import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Routing } from "routes";
import axios from "axios";
import moment from "moment-timezone";

import { useChainId } from "hooks/useChainId";
import { KPI_STATUS_CONFIRMED, KPI_STATUS_NOT_STARTED, KPI_STATUS_TO_CONFIRM } from "../../constants/actionsTypes";
import { getClinics } from "actions/authActions";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import ENVIRONMENT_VARIABLES from "../../config";

import { Col, Row, Form, Button as BootstrapButton } from "react-bootstrap";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import swal from 'sweetalert';
import "./style.css";

import Loader from "../Loader";



const useStyles = makeStyles(() => ({
  txt: {
    fontFamily: "Poppins",
    color: "#2f4858",
  },
  p: {
    fontFamily: "Poppins",
    borderColor: "#2f4858",
    color: "#2f4858",
    fontWeight: "500",
  },
}));


export const KpiDashboard = ({ userKPISummary }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectUrl = (url, data) => {
    history.push({
      pathname: url,
      state: data,
    });
  };  
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const chainId = useChainId();
  const loggedIN = useSelector((state) => state.adminAuthReducer.adminUser);
  const isChainManager = useSelector((state) => state.adminAuthReducer.adminUser.Role.id===2);
  
  const clinics = useSelector((state) => state.adminAuthReducer.clinics);
  const kpiData = useSelector((state) => state.kpiPlannerReducer.userKPISummary);

  const queryParams = new URLSearchParams(window.location.search);
  const clinicId = queryParams.get("clinicId");
  const yearId = queryParams.get("year");

  const [selectedClinic, setSelectedClinic] = useState(clinicId);
  const [selectedYear, setSelectedYear] = useState(yearId || moment().year());
  const [yearsList, setYearsList] = useState([
    moment().year(),
    moment().year() + 1,
    moment().year() + 2,
    moment().year() + 3,
  ]);


  /**
   * USEEFFECT RE-RENDERS
   */

  // FIRST RENDER
  useEffect(() => {
    dispatch(getClinics(chainId));
    getNumbeOfYears();
    filterKpi();
  }, []);

  // CALL API TO GET YEARS WITH PROPOSED KPI DATA + FUTURE YEARS (CURRENT+3) AS OPTIONS FOR YEAR SELECTION DROP DOWN
  const getNumbeOfYears = async () => {
    try {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "get",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/getKpiYears",
      };
      const response = await axios(api);

      if (response.status === 200) {
        let dataYearsList = [
          ...response.data.data,
          moment().year(),
          moment().year() + 1,
          moment().year() + 2,
          moment().year() + 3,
        ];
        dataYearsList = [...new Set(dataYearsList.sort((a, b) => a - b))];
        setYearsList(dataYearsList);
      }
    } catch (error) {}
  };

  // CALL API TO GET DASHBOARD DATA
  const filterKpi = () => {
    dispatch(kpiPlannerAction.getUserKPISummary(0, selectedClinic, selectedYear));
  };  

  /**
   * HANDLE CONTROL
   */

  // #384: CALL DELETE API TO SET isActive='false' TO THE RELEVANT ENTRIES DEPENDING ON TYPE (chain/clinic kpi)
  const handleDeleteButton = (year, clinicId, type) => {
    // swal to confirm -> call api -> if success: call filterKpi() again, if failed: error swal
    const swalMsg = type==='chain_kpi'
                      ? `This action will delete the KPI Planner for the year ${year} and all associated KPIs for the branches and doctors created during the ${year}. Are you sure you want to proceed with this deletion?`
                      : `This action will delete the ${clinicId} KPI Planner for the year ${year} and all associated doctor KPIs under it. Are you sure you want to proceed with this deletion?`
    swal({
      title: "Warning!",
      text: swalMsg,
      icon: "warning",
      buttons: [
        "Cancel",
        "Yes"
      ]
    }).then(function (isConfirm){
      if (isConfirm) {
        setLoading(true);
        kpiPlannerAction.setKpiInactive(year, clinicId, type).then(response => {
          setLoading(false)
          if (response) {
              swal(response.message, {
                  icon: "success",
              }).then(() => {
                  filterKpi()
              });
          }
        })
      }
    })
  }

  /**
   * UI RENDERING
   */

  // CALL LOADING ELEMENT WHEN LOADING STATE IS TRUE (did you know that a loading icon is called a throbber?)
  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={0} md={3} lg={5}></Grid>

        <Grid item xs={12} md={9} lg={7}>
          {/* ******** FILTERS ******** */}
          <Grid container spacing={2}>
            {/* Clinic Filter (except for Clinic Manager) */}
            <Grid item xs={12} md={4} lg={5} className="mb-1 justify-content-center">
              {loggedIN.Role.id !== 3 && (
                <>
                  <p className={classes.p} style={{ marginBottom: '0.2rem' }}> Filter By: </p>
                  <Form.Group id="Reference" className="mb-3">
                    <Form.Select
                      value={selectedClinic}
                      onChange={(e) => {
                        e.preventDefault();
                        setSelectedClinic(e.target.value);
                      }}
                      className={classes.p}
                      id="filter-clinic"
                    >
                      <option value="">All clinics</option>
                      {clinics.map((c) => (
                        <option value={c.clinicId.toUpperCase()}>{c.clinicId}</option>
                      ))} 
                    </Form.Select>
                  </Form.Group>
                </>
              )}
            </Grid>
            {/* Year Filter  */}
            <Grid item xs={12} md={4} lg={5}>
              <p className={classes.p} style={{ marginBottom: '0.2rem' }}> KPI Year:</p>
              <Form.Group id="year" className="mb-3">
                <Form.Select
                  value={selectedYear}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedYear(e.target.value);
                  }}
                  className={classes.p}
                  id="filter-year"
                >
                  {yearsList?.map((y, i) => (
                    <option key={i} value={y}>
                      {y}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid>
            {/* Apply Button */}
            <Grid item xs={12} md={4} lg={2}>
              {/* <p className={classes.p}></p> */}
              <span className="button-color">
                <BootstrapButton
                  onClick={filterKpi}
                  variant="primary"
                  className="animate-up-2 btn btn-gray-800 btn-lg mt-3"
                  type="button"
                >
                  Apply
                </BootstrapButton>
              </span>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Row className="justify-content-left">
            <Col xs={12} lg={12}>
              <table className="kpi-dashboard-table">
                {/* <tbody className={classes.p}> */}
                <tbody>
                  {/* HEADER */}
                  {/* <tr className={classes.p} style={{ color: "#2f4858" }}> */}
                  <tr>
                    {/* <th scope="col" style={{ color: "#2f4858" }}> */}
                    <th scope="col">
                      Entity
                    </th>
                    {/* <th scope="col" style={{ color: "#2f4858" }}> */}
                    <th scope="col">
                      Parent
                    </th>
                    {/* <th scope="col" style={{ color: "#2f4858" }}>Date Created</th> */}
                    <th scope="col">Date Created</th>
                    {/* <th scope="col" style={{ color: "#2f4858" }}>ACTION</th> */}
                    <th scope="col">Action</th>
                  </tr>

                  {/* RENDER THE REST OF THE ROW BASED ON DATA RETURNED FROM getUserKpiSummary ENDPOINT */}

                  {/* PARENT KPIs (highest level returned by getUserKpiSummary, depending on user role) */}
                  {kpiData?.map((item, index) => {
                    return (
                      <>
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              item.status.text === "Confirmed"
                                ? "#33ff33"
                                : item.status.text === "To Confirm"
                                ? "#ffff33"
                                : item.status.text === "Proposed"
                                ? "#ffb833"
                                : item.status.text === "Not Started"
                                ? "#ff3333"
                                : "",
                            // fontFamily: "Poppins",
                            // color: "#2f4858",
                            // fontSize: "2em",
                            // borderColor: "#2f4858",
                          }}
                        >
                          <td>{item.entryName}</td>
                          <td>{item.parentName}</td>
                          <td>{moment(item.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                          <td>
                            <tr
                              // className={classes.p}
                              // style={{
                              //   display: "flex",
                              //   justifyContent: "flex-start",
                              // }}
                            >
                              <td className="kpi-action-button-td">
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => {
                                    let _url = Routing.MultiClinic.path + `?year=${selectedYear}&viewMode=`;
                                    switch (item.type) {
                                      case "chain_kpi": {
                                        _url += `chain&_id=${item._id}`;
                                        break;
                                      }

                                      case "clinic_kpi": {
                                        _url += `clinic&_id=${item._id}&clinicId=${item.entryName}`;
                                        break;
                                      }
                                      case "doctor_kpi": {
                                        _url += `doctor&_id=${item._id}&doctorId=${
                                          item.entryId ? item.entryId : item.entryName
                                        }&clinicId=${item?.parentName ? item.parentName : item.entryName}`;
                                        break;
                                      }
                                      default: {
                                        _url = _url + "";
                                      }
                                    }

                                    redirectUrl(_url);
                                  }}
                                  // style={{ fontWeight: "bold" }}
                                >
                                  View
                                </Button>
                              </td>
                              {item.status._id === KPI_STATUS_NOT_STARTED && (
                                <td className="kpi-action-button-td">
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() =>
                                      redirectUrl(Routing.MultiClinic.path + `?year=${selectedYear}&_id=${item._id}`)
                                    }
                                    // style={{ fontWeight: "bold" }}
                                  >
                                    Propose
                                  </Button>
                                </td>
                              )}
                              {item.status._id === KPI_STATUS_TO_CONFIRM &&
                                isChainManager &&
                                item.type === "chain_kpi" && (
                                  <td className="kpi-action-button-td">
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() =>
                                        redirectUrl(
                                          Routing.MultiClinic.path +
                                            `?year=${selectedYear}&viewMode=chain&confirmMode=1&_id=${item._id}`
                                        )
                                      }
                                      // style={{ fontWeight: "bold" }}
                                    >
                                      {" "}
                                      Confirm{" "}
                                    </Button>
                                  </td>
                                )}
                              {isChainManager && (
                                <td className="kpi-action-button-td">
                                  <Button
                                      onClick={() => {handleDeleteButton(selectedYear, selectedClinic, 'chain_kpi')}}
                                      variant="outline-primary"
                                      size="sm"
                                      // style={{ fontWeight: "bold" }}
                                  >
                                    {" "}
                                    Delete{" "}
                                  </Button>
                                </td>
                              )}
                            </tr>
                          </td>
                        </tr>

                        {/* CLINIC KPIs (rendered when highest level is chain, ie. the user is a chain manager) */}
                        {item.Clinics?.map((clinic, clinicIndex) => {
                          if (clinic.KpiClinics.length) {
                            return (
                              <>
                                <tr
                                  key={clinicIndex}
                                  style={{
                                    backgroundColor:
                                      clinic.KpiStatus.statusName === "Confirmed"
                                        ? "#33ff33"
                                        : clinic.KpiStatus.statusName === "To Confirm"
                                        ? "#ffff33"
                                        : clinic.KpiStatus.statusName === "Proposed"
                                        ? "#ffb833"
                                        : clinic.KpiStatus.statusName === "Not Started"
                                        ? "#ff3333"
                                        : "",
                                    // fontFamily: "Poppins",
                                    // color: "#2f4858",
                                    // fontSize: "2em",
                                    // borderColor: "#2f4858",
                                  }}
                                >
                                  <td style={{ paddingLeft: '3rem' }}>{clinic.KpiClinics[0].clinicId}</td>
                                  <td>{item.entryName}</td>
                                  <td>{moment(clinic.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                                  <td>
                                    <tr
                                      // className={classes.p}
                                      // style={{
                                      //   display: "flex",
                                      //   justifyContent: "flex-start",
                                      // }}
                                    >
                                      <td className="kpi-action-button-td">
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          onClick={() =>
                                            redirectUrl(
                                              Routing.MultiClinic.path +
                                                `?year=${selectedYear}&viewMode=clinic&_id=${clinic._id}&clinicId=${clinic.KpiClinics[0].clinicId}`
                                            )
                                          }
                                          // style={{ fontWeight: "bold" }}
                                        >
                                          {" "}
                                          View{" "}
                                        </Button>
                                      </td>
                                      {clinic.KpiStatus._id === KPI_STATUS_TO_CONFIRM && (
                                        <td className="kpi-action-button-td">
                                          <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() =>
                                              redirectUrl(
                                                Routing.MultiClinic.path +
                                                  `?year=${selectedYear}&viewMode=clinic&confirmMode=1&_id=${clinic._id}&clinicId=${clinic.KpiClinics[0].clinicId}`
                                              )
                                            }
                                            // style={{ fontWeight: "bold" }}
                                          >
                                            {" "}
                                            Confirm{" "}
                                          </Button>
                                        </td>
                                      )}
                                      {isChainManager && (
                                        <td className="kpi-action-button-td">
                                          <Button
                                              onClick={() => {handleDeleteButton(selectedYear, clinic.KpiClinics[0].clinicId, 'clinic_kpi')}}
                                              variant="outline-primary"
                                              size="sm"
                                              // style={{ fontWeight: "bold" }}
                                          >
                                            {" "}
                                            Delete{" "}
                                          </Button>
                                        </td>
                                      )}
                                    </tr>
                                  </td>
                                </tr>
                                
                                {/* DOCTOR KPIs (rendered when highest level is chain, ie. the user is a chain manager) */}
                                {clinic.Doctors?.map((doctor, doctorIndex) => {
                                  return (
                                    <>
                                      {!!doctor.KpiDoctors.length && (
                                        <tr
                                          key={doctorIndex}
                                          style={{
                                            backgroundColor:
                                              doctor.KpiStatus.statusName === "Confirmed"
                                                ? "#33ff33"
                                                : doctor.KpiStatus.statusName === "To Confirm"
                                                ? "#ffff33"
                                                : doctor.KpiStatus.statusName === "Proposed"
                                                ? "#ffb833"
                                                : doctor.KpiStatus.statusName === "Not Started"
                                                ? "#ff3333"
                                                : "",
                                            // fontFamily: "Poppins",
                                            // color: "#2f4858",
                                            // fontSize: "2em",
                                            // borderColor: "#2f4858",
                                          }}
                                        >
                                          <td style={{ paddingLeft: '6rem' }}>{doctor?.KpiDoctors[0]?.Doctor.name}</td>
                                          <td>{clinic.KpiClinics[0].clinicId}</td>
                                          <td>{moment(doctor.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                                          <td>
                                            <tr
                                              // className={classes.p}
                                              // style={{
                                              //   display: "flex",
                                              //   justifyContent: "flex-start",
                                              // }}
                                            >
                                              <td className="kpi-action-button-td"> 
                                                <Button
                                                  variant="outline-primary"
                                                  size="sm"
                                                  onClick={() =>
                                                    redirectUrl(
                                                      Routing.MultiClinic.path +
                                                        `?year=${selectedYear}&viewMode=doctor&_id=${doctor._id}&doctorId=${doctor.KpiDoctors[0].doctorId}&clinicId=${clinic.KpiClinics[0].clinicId}`
                                                    )
                                                  }
                                                  // style={{ fontWeight: "bold" }}
                                                >
                                                  {" "}
                                                  View{" "}
                                                </Button>
                                              </td>
                                            </tr>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            );
                          }
                        })}

                        {/* DOCTOR KPIs (rendered when highest level is clinic, ie. the user is a clinic manager) */}
                        {item.Doctors?.map((doctor, doctorIndex) => {
                          return (
                            <>
                              <tr
                                key={doctorIndex}
                                style={{
                                  backgroundColor:
                                    doctor.KpiStatus.statusName === "Confirmed"
                                      ? "#33ff33"
                                      : doctor.KpiStatus.statusName === "To Confirm"
                                      ? "#ffff33"
                                      : doctor.KpiStatus.statusName === "Proposed"
                                      ? "#ffb833"
                                      : doctor.KpiStatus.statusName === "Not Started"
                                      ? "#ff3333"
                                      : "",
                                  // fontFamily: "Poppins",
                                  // color: "#2f4858",
                                  // fontSize: "2em",
                                  // borderColor: "#2f4858",
                                }}
                              >
                                <td style={{ paddingLeft: '3rem' }}>{doctor?.KpiDoctors[0]?.Doctor.name}</td>
                                <td>{doctor?.parentName ? doctor.parentName : item.entryName}</td>
                                <td>{moment(doctor.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                                <td>
                                  <tr
                                    // className={classes.p}
                                    // style={{
                                    //   display: "flex",
                                    //   justifyContent: "flex-start",
                                    // }}
                                  >
                                    <td className="kpi-action-button-td">
                                      <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() =>
                                          redirectUrl(
                                            Routing.MultiClinic.path +
                                              `?year=${selectedYear}&viewMode=doctor&_id=${doctor._id}&doctorId=${
                                                doctor.KpiDoctors[0].doctorId
                                              }&clinicId=${doctor?.parentName ? doctor.parentName : item.entryName}`
                                          )
                                        }
                                        // style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        View{" "}
                                      </Button>
                                    </td>
                                    {doctor.status === KPI_STATUS_TO_CONFIRM && (
                                      <td className="kpi-action-button-td">
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          onClick={() =>
                                            redirectUrl(
                                              Routing.MultiClinic.path +
                                                `?year=${selectedYear}&viewMode=doctor&confirmMode=1&_id=${
                                                  doctor._id
                                                }&doctorId=${doctor.KpiDoctors[0].doctorId}&clinicId=${
                                                  doctor?.parentName ? doctor.parentName : item.entryName
                                                }`
                                            )
                                          }
                                          // style={{ fontWeight: "bold" }}
                                        >
                                          Confirm
                                        </Button>
                                      </td>
                                    )}
                                  </tr>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>

              {/* BUTTON TO INITIATE ADDING KPI PLAN */}
              {/* #384: ONLY AVALAIBLE FOR CHAIN MANAGERS AND IF THE RETURNED DATA FROM getUserKpiSummary IS EMPTY (no currently active KPIs for the relevant year) */}
              {isChainManager && kpiData.length===0 && (
                <Button
                  variant="outline-gray-600"
                  className="d-inline-flex align-items-center"
                  onClick={() =>
                    redirectUrl(Routing.MultiClinic.path + `?year=${selectedYear}`, {
                      page: 1,
                      chainId: 1,
                    })
                  }
                >
                  + KPI Planner
                </Button>
              )}
            </Col>
          </Row>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, null)(KpiDashboard);
