import React, { useEffect, useState, useMemo, useRef, useLayoutEffect } from "react";
import { Routing } from "routes";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import decode from "jwt-decode";
import _ from "lodash";

import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import clsx from 'clsx'
import swal from 'sweetalert';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

import {
    KPI_STATUS_CONFIRMED,
    KPI_STATUS_NOT_STARTED,
    KPI_STATUS_PROPOSED,
    KPI_STATUS_TO_CONFIRM
} from "../../constants/actionsTypes";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import { numberWithCommas, roundToTwo } from "utils/parser.js";
import useWindowDimensions from "hooks/useWindowDimensions";

import Loader from "components/Loader"



const useStyles = makeStyles(() => ({
    root: {
        "& .Mui-active .MuiStepIcon-root": { color: "white" },
        "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
        "& .MuiStepLabel-root .MuiStepLabel-label": { fontFamily: 'Poppins', fontSize: '1em', paddingBottom: '1em', fontWeight: '500', color: '#2f4858' }
    },
    cols: {
        paddingLeft: '2em',
        paddingRight: '2em'
    }
}));



// #400: add isProgressVisible as props received from MultiClinic.js to determine whether progress bar is shown/not
const DoctorLevel = ({ kpiProgress, step, nextStep, setName, prevStep, handleFormData, values, getDoctorKPIPlanningData, updateDocktorKpi, singleProcess, viewMode, getReferences, getAllocates, year, isProgressVisible }) => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true)

    const doctorKPIData = useSelector(state => state.kpiPlannerReducer.doctorKPIData);
    const doctorReference = useSelector((state) => state.kpiPlannerReducer.referencesClinic);
    const allocate = useSelector((state) => state.kpiPlannerReducer.allocatedByClinic)
    const steps = [
        'Propose Chain Target',
        'Propose Clinic Target',
        'Propose Doctor Target',
        'Confirm Doctor Target',
        'Confirm Clinic Target',
        'Confirm Chain Target'
    ];

    const [treatmentsData, setTreatmentsData] = useState([])
    const [optionReference, setOptionReference] = useState(doctorKPIData?.reference?._id);
    const [allocateBy, setAllocateBy] = useState(doctorKPIData?.allocate?._id);

    const [ttlHrsLeft, setTtlHrsLeft] = useState(0);            // [2024-07-15] #399: STATE TO STORE (INPUT TARGET HRS - ADJ TARGET HRS)
    const [marks, setMarks] = useState([]);
    const [maxMark, setMaxMark] = useState(0);
    const [errors, setErrors] = useState({
        targetTotalRevenue: null,
        targetTotalHours: null, 
        treatmentTargetTotalHours: null                         // [2024-07-18] #399: STORING ERROR MESSAGES FOR INVALID TARGET VALUES FOR EACH TREATMENT 
    });

    const [targetTotalRevenue, setTargetTotalRevenue] = useState(doctorKPIData?.targetTtlRev)
    const [targetTotalHours, setTargetTotalHours] = useState(doctorKPIData?.targetTtlHrs)
    const [targetRevenueHours, setTargetRevenueHours] = useState(doctorKPIData?.targetRevHrs)

    const queryParams = new URLSearchParams(window.location.search);
    const confirmMode = queryParams.get('confirmMode');

    const doctorId = useMemo(() => {
        const accessToken = localStorage.getItem("accessToken")
        if (accessToken !== "undefined" && accessToken !== null) {
            const userProfile = decode(accessToken)
            const user = userProfile.user;
            if (user.doctor) {
                return user.doctor.doctorId;
            }
        }
    }, [])

    // #400: REF TO KEEP TRACK OF PROGRESS BOX HEIGHT
    const [progressHeight, setProgressHeight] = useState(0);
    const progressRef = useRef(null);
    // #400: TRACK WINDOW SIZE TO CALCULATE TREATMENT CARD HEIGHT
    const { innerWidth, innerHeight, outerWidth, outerHeight } = useWindowDimensions();
    const [treatHeight, setTreatHeight] = useState(0);

    const prevStep1 = () => {
        history.push(Routing.KpiPlanner.path);
    }


    /**
     * USEEFFECT RE-RENDERS
     */

    // FIRST RENDER
    useEffect(() => {
        setName(doctorKPIData?.doctorName);
        getReferences()
        getAllocates()
    }, []);

    // ON viewMode CHANGE
    useEffect(() => {
        if (viewMode) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getDoctorKPIPlanningData(queryParams.get('doctorId'), null, Number(_id), year);
        }
    }, [viewMode])

    // ON optionReference CHANGE
    useEffect(() => {
        if (optionReference) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getDoctorKPIPlanningData(doctorId, Number(optionReference), Number(_id), year);
        }
    }, [optionReference])

    // ON DATA (doctorKPIData) CHANGE
    useEffect(() => {
        if (!_.isEmpty(doctorKPIData)) {
            let shallowTreatment = _.cloneDeep(doctorKPIData.treatments)
            // #397: ADD referenceNoCases AND targetNoCases
            shallowTreatment = shallowTreatment.map(el => {
                return {
                    treatmentId: el.treatmentId,
                    treatmentName: el.treatmentName,
                    referenceTotalRevenue: el.trtmntRefTtlRev,
                    referenceTotalHours: el.trtmntRefTtlHrs,
                    referenceRevenueHours: el.trtmntRefRevHrs,
                    targetTotalRevenue: el.trtmntTargetTtlRev,
                    targetTotalHours: el.trtmntTargetTtlHrs,
                    targetRevenueHours: el.trtmntTargetRevHrs,
                    percentage: el?.percentage || 0,
                    referenceNoCases: el?.trtmntRefNoCases || 0,
                    targetNoCases: el?.trtmntTargetNoCases || 0,
                    isTargetTtlHrsInRange: true
                }
            })
            setTreatmentsData(shallowTreatment)
            setTargetTotalRevenue(doctorKPIData?.targetTtlRev)
            setTargetTotalHours(doctorKPIData?.targetTtlHrs)
            setTargetRevenueHours(doctorKPIData?.targetRevHrs)

            setOptionReference(doctorKPIData?.reference?._id)
            setAllocateBy(doctorKPIData?.allocate?._id)
            setMaxMark(Number(doctorKPIData?.targetTtlHrs));
            setMarks(
                [
                    {
                        value: 0,
                        label: '0 Hour',
                    },
                    {
                        value: Number(doctorKPIData?.targetTtlHrs),
                        label: `${Number(doctorKPIData?.targetTtlHrs)} Hours`,
                    },
            ])            

            // #399: SET ttlHrsLeft
            setTtlHrsLeft(calculateTtlHrsLeft(doctorKPIData?.targetTtlHrs, shallowTreatment));

            // if (doctorKPIData?.status?._id < KPI_STATUS_TO_CONFIRM && !viewMode) {
            //     const totalRevenueHours = Number(targetTotalHours) ? targetTotalRevenue / targetTotalHours : 0
            //     let shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatment, Number(targetTotalRevenue), Number(targetTotalHours), totalRevenueHours, Number(doctorKPIData?.allocate?._id))
            //     setTreatmentsData(shallowTreatmensData)
            // }
            setLoading(false)
        }
    }, [doctorKPIData])

    // #399: IF ttlHrsLeft IS NEGATIVE (SUM OF TREATMENTS > INPUT TARGET) THEN SHOW WARNING TOAST 
    useEffect(() => {
        if (ttlHrsLeft<0) {
            toast.error('Sum of target hours per treatment should not exceed input target total hours.', {
                toastId: 'sum-exceeded'         // THIS WILL PREVENT MULTIPLE SAME TOAST DISPLAY WHEN ONE IS ALREADY SHOWN
            })
        } 
        // else {
        //     toast.dismiss('sum-exceeded');
        // }
    }, [ttlHrsLeft])

    // #400: UPDATE progressHeight STATE BASED ON REF CURRENT CLIENT HEIGHT
    useLayoutEffect(() => {
        if (progressRef.current && progressRef.current.clientHeight) {
            setProgressHeight(progressRef.current.clientHeight);
        }
    })

    // #400: UPDATE TREATMENT HEIGHT IF WINDOW IS ZOOM IN/OUT, RESIZED, OR IF isProgressVisible IS TOGGLED ON/OFF
    useEffect(() => {
        const tempTreatHeight = isProgressVisible
                                ? ((0.3 * innerHeight) * (2-((outerWidth-10)/innerWidth))) 
                                : ((0.3 * innerHeight) * (2-((outerWidth-10)/innerWidth))) + progressHeight
        setTreatHeight(tempTreatHeight);
    }, [innerWidth, innerHeight, outerWidth, outerHeight, progressHeight, isProgressVisible])


    /**
     * HANDLE CONTROL
     */

    // REFERENCE CHANGE -> basically won't be used since the control is disabled 
    const handleChangeOptionsReference = (e) => {
        const optionReference = e.target.value
        setOptionReference(Number(optionReference))
        setLoading(true)

        const queryParams = new URLSearchParams(window.location.search);
        const _id = queryParams.get('_id');
        getDoctorKPIPlanningData(doctorId, Number(optionReference), Number(_id), year);
    }

    // ALLOCATE CHANGE
    const handleChangeAllocateInput = (e) => {
        setAllocateBy(e.target.value)
        const totalRevenueHours = Number(targetTotalHours) ? Number(targetTotalRevenue) / Number(targetTotalHours) : 0
        let shallowTreatmensData = _.cloneDeep(treatmentsData)

        // CALCULATE NEW VALUES THEN RE-ORDER
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(targetTotalHours), Number(e.target.value))
        shallowTreatmensData = _.orderBy(shallowTreatmensData, ['referenceTotalRevenue', 'treatmentId'], ['desc', 'asc'])

        // #399: CALCULATE REMAINING HRS LEFT -> SET ALL TREATMENTS' TARGET TO NOT IN RANGE (red text) IF REMAINING<0
        const remaining = calculateTtlHrsLeft(+targetTotalHours, shallowTreatmensData);
        shallowTreatmensData = shallowTreatmensData.map(el => {
            if (el.targetTotalHours<0 || remaining<0) {
                return {
                    ...el,
                    isTargetTtlHrsInRange: false
                }
            } else {
                return {
                    ...el,
                    isTargetTtlHrsInRange: true
                }
            }
        })

        setTreatmentsData(shallowTreatmensData)
        setTtlHrsLeft(remaining);
    }

    // CALCULATE NEW VALUES AFTER ALLOCATE CHANGE
    const changeDataTreatementAcordingToAllocateId = (tempTreatmentData, totalHours, allocateId) => {
        let shallowListTreatments = _.cloneDeep(tempTreatmentData);

        // SIMPLE AVERAGE
        // PERCENTAGE = 100/(shallowListTreatments.length)
        // HOURS = totalHours/(shallowListTreatments.length)
        if (allocateId == 1) {
            const totalTreatmentsCount = shallowListTreatments.length;

            let percentage = roundToTwo(100 / +totalTreatmentsCount) || 0;
            let targetHours = roundToTwo(+totalHours / +totalTreatmentsCount) || 0;

            shallowListTreatments = shallowListTreatments.map(el => {
                return {
                    ...el,
                    percentage: percentage,
                    targetTotalHours: targetHours
                }
            })
        } 

        // #397: USES HOURS METRIC FOR CONTRIBUTION RATIO CALCULATION
        // HISTORICAL CONTRIBUTION
        // PERCENTAGE (PER TREATMENT) = (el.referenceTotalHours/refTtlHrs)*100
        // HOURS (PER TREATMENT) = (el.referenceTotalHours/refTtlHrs)*totalHours
        else if (allocateId == 2) {
            shallowListTreatments = shallowListTreatments.map(el => {
                let percentage = roundToTwo(el.referenceTotalHours / doctorKPIData.refTtlHrs * 100) || 0;
                let targetHours = roundToTwo(el.referenceTotalHours / doctorKPIData.refTtlHrs * totalHours) || 0;

                return {
                    ...el,
                    percentage: percentage,
                    targetTotalHours: targetHours,
                }
            })
        } 

        // MANUAL ADJUSTMENT
        else if (allocateId == 4) {
            shallowListTreatments = shallowListTreatments.map(el => {
                return {
                    ...el
                }
            })
        }

        // #397: ADDED targetNoCases CALCULATION
        // ADD REVENUE (PER TREATMENT) = (el.referenceRevenueHours*el.targetTotalHours)
        // ADD NO CASES (PER TREATMENT) = Math.ceil((el.referenceNoCases/el.referenceTotalHours)*el.targetTotalHours)
        shallowListTreatments = shallowListTreatments.map(el => {
            return {
                ...el,
                targetTotalRevenue: roundToTwo(+(+el.referenceTotalRevenue/+el.referenceTotalHours)*+el.targetTotalHours) || 0,
                targetNoCases: Math.ceil((+el.referenceNoCases/+el.referenceTotalHours)*+el.targetTotalHours) || 0
            }
        })

        return shallowListTreatments
    }

    // TARGET TOTAL HOURS CHANGE (in frozen overview table)
    const handleInputTargetTotalHours = (e) => {
        let { value } = e.target
        setMaxMark(Number(value))

        const shallowMarks = _.cloneDeep(marks)
        console.log("#418 ERROR shallowMarks: ", shallowMarks);
        shallowMarks[1] = {
            value: Number(value),
            label: `${Number(value)} Hours`
        }
        console.log("#418 ERROR shallowMarks 2: ", shallowMarks)
        setMarks(shallowMarks)

        const totalRevenueHours = Number(value) ? targetTotalRevenue / value : 0
        setTargetTotalHours(e.target.value)
        setTargetRevenueHours(totalRevenueHours)

        // CALCULATE NEW VALUES THEN RE-ORDER
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(e.target.value), allocateBy)
        shallowTreatmensData = _.orderBy(shallowTreatmensData, ['referenceTotalRevenue', 'treatmentId'], ['desc', 'asc'])

        // #399: CALCULATE REMAINING HRS LEFT -> SET ALL TREATMENTS' TARGET TO NOT IN RANGE (red text) IF REMAINING<0
        const remaining = calculateTtlHrsLeft(+value, shallowTreatmensData);
        shallowTreatmensData = shallowTreatmensData.map(el => {
            if (el.targetTotalHours<0 || remaining<0) {
                return {
                    ...el,
                    isTargetTtlHrsInRange: false
                }
            } else {
                return {
                    ...el,
                    isTargetTtlHrsInRange: true
                }
            }
        })

        setTreatmentsData(shallowTreatmensData)
        setTtlHrsLeft(remaining);
    }

    // TARGET HOURS PER TREATMENT CHANGE (slider + input box)
    const handleChangeRanges = (e) => {
        let { name: index, value } = e.target;
        let shallowTreatments = _.cloneDeep(treatmentsData);

        // VALIDATE 
        if (value<0) value=0;                       // #399: PREVENT NEGATIVE VALUES
        value = +parseFloat(value).toFixed(2);      // #399: PREVENT MORE THAN 2 DECIMAL VALUES

        shallowTreatments[index].percentage = roundToTwo((+value/+maxMark)*100) || 0;
        shallowTreatments[index].targetTotalRevenue = roundToTwo(value * roundToTwo(shallowTreatments[index].referenceTotalRevenue / shallowTreatments[index].referenceTotalHours)) || 0;

        shallowTreatments[index].targetTotalHours = value
        if (shallowTreatments[index].targetTotalHours) {
            shallowTreatments[index].targetRevenueHours = roundToTwo(shallowTreatments[index].targetTotalRevenue / shallowTreatments[index].targetTotalHours)
            // #399: CALCULATE NO. CASES = Math.ceil((refNoCases / refTotalHrs) * targetTotalHrs)
            shallowTreatments[index].targetNoCases = Math.ceil(((+shallowTreatments[index].referenceNoCases / +shallowTreatments[index].referenceTotalHours) * +shallowTreatments[index].targetTotalHours) || 0)
        }
        shallowTreatments = _.orderBy(shallowTreatments, ['referenceTotalRevenue', 'treatmentId'], ['desc', 'asc'])

        // #399: CALCULATE REMAINING HRS LEFT -> SET TREATMENT TO IN RANGE IF:
        // - targetTotalHrs is positive
        // - remaining >= 0 (adj target <= input target)
        // - value = 0 (lowest it can be, so even though remaining<0 still, it's not the problem anymore)
        const remaining = calculateTtlHrsLeft(targetTotalHours, shallowTreatments);
        if (((value > 0) && (remaining>=0)) || (value===0)) {
            shallowTreatments[index].isTargetTtlHrsInRange = true;
        } else {
            shallowTreatments[index].isTargetTtlHrsInRange = false;
        }
        // #399: IF REMAINING >= 0 THEN SET ALL OTHER TREATMENTS TO IN RANGE
        if (remaining>=0) {
            shallowTreatments = shallowTreatments.map(el =>  {
                return {
                    ...el,
                    isTargetTtlHrsInRange: true
                }
            })
        }
        setTtlHrsLeft(remaining);

        setTreatmentsData(shallowTreatments)
        setAllocateBy(4)
    }

    // #399: CALCULATE HOURS LEFT = (INPUT TARGET - SUM OF ALL TREATMENT TARGET)
    const calculateTtlHrsLeft = (targetHrs, tempTreatmentsData) => {
        const adjTtlHrs = handleTotalHours(tempTreatmentsData, true);
        const remaining = +((+targetHrs - +adjTtlHrs).toFixed(2));
        return remaining;
    }


    /**
     * HANDLE ADJUSTED TOTAL VALUES 
     */

    // ADJUSTED TOTAL TARGET HOURS AS A WHOLE (based on SUM of all treatmets' target hours)
    const handleTotalHours = (data, numberReturn = false) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetTotalHours)
            }, 0)
            return numberReturn ? roundToTwo(total) : numberWithCommas(roundToTwo(total))
        } else {
            return 0
        }
    }

    // ADJUSTED TOTAL TARGET REVENUE AS A WHOLE (based on SUM of all treatmets' target revenue)
    const handleTotalRevenue = (data, numberReturn = false) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetTotalRevenue)
            }, 0)
            return numberReturn ? roundToTwo(total) : numberWithCommas(roundToTwo(total))
        } else {
            return 0
        }
    }


    /**
     * BUTTONS
     */

    const getButtonLabel = () => {
        switch (doctorKPIData.status._id) {
            case 1: return 'Propose';
            case 2: return 'Update';
            case 3: return 'Confirm';
            case 4: return 'Confirmed';
            default: return 'N/A';
        }
    }

    // PROPOSE BUTTON -> PROPOSE KPI DETAILS
    const sendAPiPostDoctorProposeKpi = (e) => {
        const dataRequest = {
            "_id": doctorKPIData?._id,
            "kpiStatusId": KPI_STATUS_TO_CONFIRM,
            "doctorId": doctorKPIData.doctorId,
            "referenceId": doctorKPIData.reference._id,
            "allocateId": allocateBy,
            "referenceTotalRevenue": doctorKPIData?.refTtlRev,
            "referenceTotalHours": doctorKPIData?.refTtlHrs,
            "targetTotalHours": parseInt(targetTotalHours),
            "targetTotalRevenue": handleTotalRevenue(treatmentsData, true),
            "referenceRevenueHours": doctorKPIData?.refRevHrs,
            "targetRevenueHours": targetRevenueHours,
            "treatments": treatmentsData.map((treat) => { 
                let newTreat = {}, key;
                for (key in treat) {
                    if (key!=='isTargetTtlHrsInRange') newTreat[key] = treat[key];
                };
                return newTreat;
             })
        }
        e.preventDefault();

        // #418: ADD CONFIRMATION POP UP TO ALLOW totalTargetHrs=0 AND AVOID ACCIDENTAL INPUT
        swal({
            title: "Save changes?",
            icon: "warning",
            buttons: [
                "Cancel",
                "Yes"
            ],
            dangerMode: true
        }).then(function(isConfirm) {
            if (isConfirm) {
                let [isFormValid, formErrors] = validateFormData(dataRequest);
                if (isFormValid) {
                    setLoading(true);
                    kpiPlannerAction.postUpdateDoctorKpiConfirm(dataRequest).then(response => {
                        setLoading(false)
                        if (response) {
                            swal(response.message, {
                                icon: "success",
                            }).then(() => {
                                prevStep1()
                            });
                        }
                    })
                } else {
                    Object.entries(formErrors).forEach(entry => {
                        const [key, value] = entry;
                        swal({
                            title: "Invalid Values",
                            text: value,
                            icon: "error"
                        })
                    })
                }
            }
        })

    }

    // VALIDATE BEFORE PROPOSING
    const validateFormData = (data) => {
        let errors = {};
        let formIsValid = true;

        // inputAmount
        if (!data?.targetTotalRevenue && data?.targetTotalRevenue!=0) {
            errors.targetTotalRevenue = "Please type total hours"
        }

        // referenceId
        if (!data?.targetTotalHours && data?.targetTotalHours!=0) {
            errors.targetTotalHours = "Please type  total hours"
        }

        // #399: add validation for sum of target total hours of all treatments
        if (ttlHrsLeft<0) {
            errors.treatmentTargetTotalHours = "Sum of target hours per treatment should not exceed input target total hours"
        }

        // #399: add validation for target hours per treatment
        if (data?.treatments) {
            data.treatments.forEach(treatment => {
                if (!treatment.targetTotalHours && treatment.targetTotalHours!=0) {
                    errors.treatmentTargetTotalHours = "Please fill in all treatment target hours"
                }
                if (treatment.targetTotalHours && treatment.targetTotalHours>data.targetTotalHours) {
                    errors.treatmentTargetTotalHours = "Treatment target hours must be less than the total target hours"
                }
                if (treatment.targetTotalHours && treatment.targetTotalHours<0) {
                    errors.treatmentTargetTotalHours = "Treatment target hours could not be negative"
                }
            })
        }

        if (Object.keys(errors)?.length) {
            formIsValid = false;
            setErrors(errors)
        }

        // #399: return 2 values
        return [formIsValid, errors]
    }

    // CONFIRM BUTTON -> CONFIRM KPI DETAILS
    const confirmKpi = (e, id) => {
        e.preventDefault();

        swal("Confirm this KPI?", {
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                setLoading(true);
                kpiPlannerAction.confirmKpi(id).then(response => {
                    setLoading(false)
                    if (response) {
                        swal(response.message, {
                            icon: "success",
                        }).then(() => {
                            history.push({
                                pathname: Routing.KpiPlanner.path
                            })
                        })
                    }
                })
            }
        });
    }


    /**
     * UI RENDERING
     */

    // CALL LOADING ELEMENT WHEN LOADING STATE IS TRUE (did you know that a loading icon is called a throbber?)
    if (loading) {
        return <Loader></Loader>
    }

    return (
        <>

            {/* #399: ADD TOAST CONTAINER TO SHOW SUM EXCEEDED ERROR */}
            <ToastContainer/>

            {/* #400: UI ADJUSTMENT TO MAXIMIZE TREATMENT SCROLLABLE PANEL */}
            {/* IF Show/Hide Progress BUTTON (CHECK MULTICLINIC) IS CLICKED -> SET isProgressVisible -> IF TRUE THEN SHOW PROGRESS TIMELINE */}
            {/* #400: ADD REF TO KEEP TRACK OF PROGRESS BOX HEIGHT */}
            {
                isProgressVisible &&
                <Box sx={{ width: '100%' }} ref={progressRef}>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="wrapper-progressBar">
                                <ul className="progressBar">
                                    {steps.map((el, index) =>
                                        <li
                                            key={index}
                                            className={clsx({
                                                'finalize-progress': kpiProgress && kpiProgress[Number(index) + 1],
                                                'selected-progress':
                                                    index === 2 && doctorKPIData?.status?._id === KPI_STATUS_NOT_STARTED
                                                    || index === 3 && doctorKPIData?.status?._id === KPI_STATUS_PROPOSED
                                                    || index === 3 && doctorKPIData?.status?._id === KPI_STATUS_TO_CONFIRM
                                            })}>
                                            {el}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Box>            
            }

            <Form 
                // onSubmit={submitFormData}            // #434: COMMENTED OUT BECAUSE IT CAUSED COMPILER ERROR
                style={{ }}
            >
                <Card className="table-wrapper shadow mb-3">
                    <Card.Body>
                        <Row className="chain-level align-items-center" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col sm={12} md={12} lg={3} className={classes.cols}>
                                <Form.Group id="Reference" className="mb-2">
                                    {/* #400: MOVED DR NAME TO TOP OF REFERENCE */}
                                    <div>
                                        <Form.Label className="doctor-kpi-name">{doctorKPIData?.doctorName}: KPI</Form.Label>
                                    </div>
                                    <div>
                                        <Form.Label className="doctor-kpi-ref-label"> Select reference: </Form.Label>
                                        <Form.Select
                                            className="doctor-kpi-ref-select" id="financialYears" value={optionReference}
                                            disabled={true}
                                            onChange={(e) => handleChangeOptionsReference(e)}
                                        >
                                            {doctorReference?.references.map((item, index) => (
                                                <option key={index} value={item.referenceId}>{item.referenceName}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Form.Group>
                                {/* #399: MOVED ALLOCATE CONTROL TO UNDER REFERENCE */}
                                <Form.Group id="AllocatedBy" className="mb-3">
                                    <Form.Label className="doctor-kpi-ref-label"> Allocate By: </Form.Label>
                                    <Form.Select value={allocateBy}
                                        onChange={handleChangeAllocateInput}
                                        className="doctor-kpi-ref-select" id="financialYears" disabled={viewMode}>
                                        {allocate?.allocateBy.map((item, index) => (
                                            <option key={index} value={item.allocateById}>{item.allocateByName}</option>

                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col sm={12} md={12} lg={9}>
                                <Row className="align-items-end">
                                    <Col sm={12} md={12} lg={9} className="mb-md-3 mb-lg-0">
                                    {/* #400: USE HTML'S <table> INSTEAD OF BOOTSTRAP'S <Table> TO MAKE STYLING EASIER */}
                                    <table className="align-items-center table-bordered table-condensed doctor-kpi-overview-table">
                                            <thead>
                                                <tr className="table-active">
                                                    <th></th>
                                                    <th>Total Revenue</th>
                                                    <th>Total Hours</th>
                                                    <th>Revenue /Hours</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="table-active">
                                                    <td>Reference:</td>
                                                    <td>${numberWithCommas(roundToTwo(doctorKPIData?.refTtlRev))}</td>
                                                    <td>{numberWithCommas(roundToTwo(doctorKPIData?.refTtlHrs))}</td>
                                                    <td>${numberWithCommas(roundToTwo(doctorKPIData?.refRevHrs))}</td>
                                                </tr>
                                                <tr className="table-active">
                                                    <td>Input Target:</td>
                                                    <td>
                                                        ${numberWithCommas(roundToTwo(doctorKPIData?.targetTtlRev))}
                                                    </td>
                                                    <td>
                                                        <Form.Group controlId="inputTarget">
                                                            <Form.Control
                                                                className="doctor-kpi-overview-input"
                                                                type="number"
                                                                min="0"
                                                                required={true}
                                                                disabled={viewMode || doctorKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                                                value={targetTotalHours}
                                                                onChange={(e) => handleInputTargetTotalHours(e)}
                                                            />
                                                        </Form.Group>
                                                        {
                                                            errors?.targetTotalHours && <span style={{ color: 'red', fontSize: '15px' }}>
                                                                {errors.targetTotalHours}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td >
                                                        ${numberWithCommas(roundToTwo(targetRevenueHours))}
                                                    </td>
                                                </tr>
                                                <tr className="table-active">
                                                    <td>Adjusted Target:</td>
                                                    <td >
                                                        ${handleTotalRevenue(treatmentsData, false)}
                                                    </td>
                                                    {/* #399: CHANGE ADJUSTED TTL HRS VALUE TO RED IF SUM IS BIGGER THAN INPUT TARGET */}
                                                    <td style={{ color: ttlHrsLeft<0 && 'red' }}>
                                                        {handleTotalHours(treatmentsData, true)}
                                                    </td>
                                                    <td >
                                                        ${numberWithCommas(roundToTwo(handleTotalRevenue(treatmentsData, true) / handleTotalHours(treatmentsData, true)))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                    <Col sm={12} md={12} lg={3} className="button-color text-end align-items-end">
                                        {!viewMode &&
                                            <Button
                                                onClick={sendAPiPostDoctorProposeKpi}
                                                variant="primary"
                                                className="animate-up-2 btn btn-gray-800 btn-lg float-none ms-5 mt-3"
                                                type="submit"
                                                disabled={viewMode || doctorKPIData.status._id > KPI_STATUS_TO_CONFIRM}>
                                                {getButtonLabel()}
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                
                {/* #400: ADJUST PANEL HEIGHT */}
                {/* #400: SET CARD HEIGHT treatHeight DYNAMICALLY USING useEffect WHICH WILL CHANGE IF WINDOW IS ZOOMED IN/OUT, RESIZED, OR IF isProgressVisible IS TOGGLED ON/OFF*/}
                <Card className="table-wrapper shadow mb-4" style={{ 
                        overflow: 'scroll', 
                        height: `${treatHeight}px`
                        // height: isProgressVisible ? '34vh' : `calc(34vh + ${progressHeight}px)` 
                    }}
                >
                    <Card.Body>
                        {/* #400: USES ORDER BY INSTEAD OF SORT BY TO REORDER BY refTtlRevenue (desc) THEN treatmentId (asc) */}
                        {_.orderBy(treatmentsData, ['referenceTotalRevenue', 'treatmentId'], ['desc', 'asc']).map((item, index) => (
                            // #400: ADJUST PT-5 -> NONE
                            <Row key={index} className="chain-level pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                <Col xs={12} md={12} lg={8} xl={8} className="table-responsive me-6">
                                    <Form.Label>{item.treatmentName}</Form.Label>
                                    {/* #400: USE HTML'S <table> INSTEAD OF BOOTSTRAP'S <Table> TO MAKE STYLING EASIER */}
                                    <table className="align-items-center table-bordered doctor-kpi-overview-table">
                                        <thead>
                                            <tr className="table-active">
                                                <th></th>
                                                <th>Total Revenue</th>
                                                <th>Total Hours</th>
                                                <th>No. of Cases</th>                           {/* #397: ADDED HEADER FOR No of Cases METRIC */}
                                                <th>Revenue/Hours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-active">
                                                <td>Reference:</td>
                                                <td>${numberWithCommas(roundToTwo(item.referenceTotalRevenue))}</td>
                                                <td>{numberWithCommas(roundToTwo(item.referenceTotalHours))}</td>
                                                <td>{item.referenceNoCases}</td>                {/* #397: ADDED REFERENCE NO CASES (CALCULATED IN BE: getDoctorKpiPlanningData AND updateClinicKpi) */}
                                                <td rowSpan={2}>${numberWithCommas(roundToTwo(item.referenceTotalRevenue / item.referenceTotalHours))} </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td>Target:</td>
                                                <td>${numberWithCommas(roundToTwo(item.targetTotalRevenue))}</td>
                                                {/* #399: ADDED EDITABLE INPUT FOR EACH TREATMENT TARGET TOTAL HOURS (USES THE SAME ONCHANGE FUNCTION AS SLIDER) */}
                                                <td>
                                                    <Form.Control
                                                        className={item.isTargetTtlHrsInRange ? "doctor-kpi-overview-input" : "doctor-kpi-overview-input-error"}
                                                        required={true}
                                                        type="number"
                                                        step="0.01"
                                                        min={0}
                                                        max={maxMark}
                                                        name={index.toString()}
                                                        value={item.targetTotalHours}
                                                        onChange={handleChangeRanges}
                                                        disabled={viewMode || doctorKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                                    />
                                                </td>
                                                {/* #397: ADDED TARGET NO CASES WHICH WILL CHANGE DYNAMICALLY ALONG WITH THE TARGET TOTAL HRS */}
                                                <td>{item.targetNoCases}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                                <Col xs={12} md={12} lg={3} xl={3} className="mt-6">
                                    {/* #399: MAKES SURE NAME PROPERTY IS CONVERTED TO STRING */}
                                    {/* #418: ADDED SX PROPS TO MAKE SURE 2ND MARK (MAX) IS ALWAYS ON THE RIGHT*/}
                                    <Slider
                                        value={item.targetTotalHours}
                                        onChange={handleChangeRanges}
                                        name={index.toString()}
                                        min={0} max={maxMark}
                                        marks={marks}
                                        sx={{ '& .MuiSlider-markLabel[data-index="1"]': { "left":"100%" } }}
                                        valueLabelDisplay="on"
                                        disabled={viewMode || doctorKPIData.status._id > KPI_STATUS_PROPOSED}
                                    />
                                </Col>
                            </Row>
                        ))}

                        {/* #400: ADJUST PADDING FROM 5+5 -> 3+2 */}
                        <Row className="justify-content-center pt-3 pb-2" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={9}></Col>
                            <Col xs={12} lg={3} className="button-color">
                                <Table className="align-items-center mb-2">
                                    <tbody>
                                        <tr className="table-active">
                                            <td>Total:</td>
                                            <td>${handleTotalRevenue(treatmentsData)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    {/* <Button variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" onClick={updateDocktorKpi(1,"Confirmed")}> Back </Button> */}
                                    {viewMode &&
                                        <>
                                            {confirmMode
                                                ?
                                                    <Button onClick={(e) => confirmKpi(e, doctorKPIData?._id)}
                                                            variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="submit"
                                                    > Confirm </Button>
                                                :
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: Routing.KpiPlanner.path,
                                                            })
                                                        }}
                                                        className="animate-up-2 btn btn-gray-800 btn-lg"
                                                        type="submit"
                                                        style={{ marginLeft: "1em" }}
                                                    >
                                                        Back to Dashboard
                                                    </Button>
                                            }
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
                
        </>
    )
}


// connecting actions to props
const mapDispatchToProps = dispatch => {
    return {
        getDoctorKPIPlanningData: (doctorId, referenceId, _id, year) => dispatch(kpiPlannerAction.getDoctorKPIPlanningData(doctorId, referenceId, _id, year)),
        getReferences: () => dispatch(kpiPlannerAction.getReferenceByClinic()),
        getAllocates: () => dispatch(kpiPlannerAction.getAllocatedByClinic())
    }
};

// export
export default connect(null, mapDispatchToProps)(DoctorLevel)
