/**
 * TAKEN FROM https://stackoverflow.com/a/59185109
 */

import { useState, useEffect } from 'react';

export default function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const innerWidth = hasWindow ? window.innerWidth : null;
    const innerHeight = hasWindow ? window.innerHeight : null;
    const outerWidth = hasWindow ? window.outerWidth : null;
    const outerHeight = hasWindow ? window.outerHeight : null;
    return {
        innerWidth,
        innerHeight,
        outerWidth,
        outerHeight
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}