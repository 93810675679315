import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import Chart from "react-apexcharts";

import "../style.css";

const FrequentDoctors = (props) => {
    const [chartOptions, setChartOptions] = useState();

    const [chartSeries, setChartSeries] = useState([{ data: [] }]);
    const frequentDoctors = props.frequentDoctors;

    useEffect(() => {
        const frequentDoctorData = _.sortBy(frequentDoctors, [
            function (n) {
                return parseInt(n.frequencyCount);
            },
        ]).reverse();
        let maxPatientsCount = 0;
        setChartSeries([
            {
                ...chartSeries[0],
                data: frequentDoctorData.map((frequentDoctor) => {
                    maxPatientsCount = Math.max(maxPatientsCount, +frequentDoctor.frequencyCount);
                    return {
                        x: frequentDoctor.drName,
                        y: parseInt(frequentDoctor.frequencyCount),
                        per: frequentDoctor.frequencyPercentage,
                    };
                }),
            },
        ]);
        setChartOptions(getGraphOptions(maxPatientsCount + 1));
    }, [frequentDoctors]);

    useEffect(() => {
        if (!props.isEmbedded) {
            window.history.replaceState(null, "Medtrik", "/patient/dashboard/frequent-doctors");
            window.scrollTo(0, 0);
        }
    }, []);

    const getGraphOptions = (maxPatientsCount) => {
        return {
            legend: {
                show: false,
            },
            chart: {
                type: "treemap",
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ["#fff"],
                    fontSize: "16px",
                },
                formatter: function (text, opt) {
                    return [text, opt.value, opt.w.globals.initialSeries[opt.seriesIndex].data[opt.dataPointIndex].per];
                },
            },
            plotOptions: {
                treemap: {
                    enableShades: true,
                    shadeIntensity: 0.5,
                    reverseNegativeShade: true,
                    colorScale: {
                        ranges: [
                            {
                                from: 1,
                                to: maxPatientsCount,
                                color: "#2c66b1",
                            },
                        ],
                    },
                },
            },
            title: {
                text: !props.isEmbedded ? "Frequent Doctors" : "",
                align: "center",
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const total = _.sum(series[seriesIndex]);
                    return `<div>
                            <p> Doctor: <b>${w.globals.categoryLabels[dataPointIndex]}</b> </p>
                            <p> No. of Patients: <b>${series[seriesIndex][dataPointIndex]}</b> </p>
                            <p> % No. of Patients: <b>${((series[seriesIndex][dataPointIndex] / total) * 100).toFixed(
                                2
                            )}%</b> </p>
                        </div>`;
                },
            },
        };
    };

    return (
        <Row className={!props.isEmbedded ? "pb-5" : "pb-4"}>
            {!props.isEmbedded ? <Col xs={3} lg={3}></Col> : null}
            <Col xs={!props.isEmbedded ? 6 : 12} lg={!props.isEmbedded ? 6 : 12}>
                {chartOptions && (
                    <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="treemap"
                        height={600}
                    />
                )}
            </Col>
        </Row>
    );
};

export default FrequentDoctors;
