import React from "react";
import "./availablePeriod.styles.css"

let avaPeriodDayStart, avaPeriodDayEnd;
let avaPeriodMonthStart, avaPeriodMonthEnd;
let avaPeriodYearStart, avaPeriodYearEnd;

const leapYear = (yr) => {
  return (yr % 4 === 0 && yr % 100 !== 0) || yr % 400 === 0;
}
const adjustDate = (y, m, d) => {
  if (m > 12) {
    y = y + Math.floor(m/12);
    m = m % 12;
  } 
  if (leapYear(y) && m % 12 === 2 && d > 29) {d = 29;}
  if (!leapYear(y) && m % 12 === 2 && d > 28) {d = 28;}
  if ((m % 12 === 4 || m % 12 === 6 || m % 12 === 9 || m % 12 === 11) && d > 30) {d = 30;}
  return new Date(y, m-1, d)
}

const AvailablePeriod = ({ target }) => {
  if ((target || '') === "today") {
    const date = new Date();
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    return (
      <div>
        <h4> {d}/{m}/{y} </h4>
      </div>
    )
  } 
  else if ((target || '').includes("week" || "weeks")) {
    let weeks = target?.slice(0, 1) - 1;
    let date = new Date();
    const nextSundayStart = new Date();
    nextSundayStart.setDate(date.getDate() + (7 - date.getDay()) + weeks * 7);
    avaPeriodDayStart = nextSundayStart.getDate();
    avaPeriodMonthStart = nextSundayStart.getMonth() + 1;
    avaPeriodYearStart = nextSundayStart.getFullYear();

    const nextSaturdayEnd = new Date();
    nextSaturdayEnd.setDate(
      date.getDate() + (7 - date.getDay()) + 6 + weeks * 7
    );
    avaPeriodDayEnd = nextSaturdayEnd.getDate();
    avaPeriodMonthEnd = nextSaturdayEnd.getMonth() + 1;
    avaPeriodYearEnd = nextSaturdayEnd.getFullYear();

    return null;

    // return (
    //   <div>
    //     <h4> Start Date (Sun): {avaPeriodDayStart}/{avaPeriodMonthStart}/{avaPeriodYearStart} </h4>
    //     <h4> End Date (Sat): {avaPeriodDayEnd}/{avaPeriodMonthEnd}/{avaPeriodYearEnd} </h4>
    //   </div>
    // );
  }
  else{
    let addPeriod = parseInt(target?.slice(0, 1));
    let date = new Date();
    let dateTarget

    if ((target || '').includes("month" || "months")) {
      dateTarget = adjustDate(date.getFullYear(), date.getMonth() + 1 + addPeriod, date.getDate());
    }
    else if ((target || '').includes("year" || "years")) {
      dateTarget = adjustDate(date.getFullYear() + addPeriod, date.getMonth() + 1, date.getDate());
    }
    else {
      return <div></div>
    }
    const dateLastWeek = new Date(dateTarget.getFullYear(), dateTarget.getMonth(), dateTarget.getDate() - 7);
    avaPeriodDayStart = dateLastWeek.getDate();
    avaPeriodMonthStart = dateLastWeek.getMonth()+1;
    avaPeriodYearStart = dateLastWeek.getFullYear();

    const dateNextWeek = new Date(dateTarget.getFullYear(), dateTarget.getMonth(), dateTarget.getDate() + 7);
    avaPeriodDayEnd = dateNextWeek.getDate();
    avaPeriodMonthEnd = dateNextWeek.getMonth()+1;
    avaPeriodYearEnd = dateNextWeek.getFullYear();

    return null;
    
    // return (
    //   <div>
    //     <h3> dateTarget: {dateTarget.getDate()}/{dateTarget.getMonth()+1}/{dateTarget.getFullYear()} </h3>
    //     <h4> -1 week: {avaPeriodDayStart}/{avaPeriodMonthStart}/{avaPeriodYearStart} </h4>
    //     <h4> +1 week: {avaPeriodDayEnd}/{avaPeriodMonthEnd}/{avaPeriodYearEnd} </h4>
    //   </div>
    // );
  }
}

export default AvailablePeriod;
export {avaPeriodDayStart, avaPeriodDayEnd, 
  avaPeriodMonthStart, avaPeriodMonthEnd, 
  avaPeriodYearStart, avaPeriodYearEnd};