import mapboxgl from '!mapbox-gl';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import _ from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../style.css';

const GeographicDistributionOfPatients = (props) => {
  const [mapMarkers, setMapMarkers] = useState([]);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const geographicDistribution = props.geographicDistribution;
  const colorsArray = ['#547FAC', '#F09230', '#857BA9'];
  const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      accessToken: 'pk.eyJ1IjoidGFjYXdlc29tZSIsImEiOiJjbDN3ZHA1NHIwMHd3M2pudDJtOWMwdDBuIn0.-nPJKYmqjtRO9gExJjecJQ',
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [103.8271, 1.3559],
      zoom: 11,
      // interactive: false
    });
  }, []);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    if (!geographicDistribution) return;
    mapMarkers.forEach((marker) => {
      marker.remove();
    });
    let markers = [];
    const branches = _.uniq(_.map(geographicDistribution, (geographicData) => geographicData.branch)).sort();
    let branchColors = {};
    branches.forEach((branch, index) => {
      branchColors[branch] = colorsArray[index];
    });
    setColorMap(branchColors);
    geographicDistribution.forEach((geographicData) => {
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundColor = branchColors[geographicData.branch];
      const marker = new mapboxgl.Marker(el)
        .setLngLat([geographicData.longitude, geographicData.latitude])
        .addTo(map.current);
      markers.push(marker);
    });
    setMapMarkers(markers);
  }, [geographicDistribution]);

  useEffect(() => {
    if (!props.isEmbedded) {
      window.history.replaceState(null, 'Medtrik', '/patient/dashboard/geographic-distribution-of-patients');
      window.scrollTo(0, 0);
    }
  }, []);

  const exportData = () => {
    const bounds = map.current.getBounds();
    // Call an API for the data with bounds returned from mapboxgl.getBounds

    const filteredRecords = geographicDistribution.filter((d) => {
      return (
        d.latitude < bounds._ne.lat &&
        d.latitude > bounds._sw.lat &&
        d.longitude < bounds._ne.lng &&
        d.longitude > bounds._sw.lng
      );
    });

    // #387: include cms_patient_id from ld2_patient (given_id from Plato) in result
    let csvContent = `data:text/csv;charset=utf-8,Patient Reference Number,Patient ID,Gender,Age,Branch,Segment\n${filteredRecords
      .map((c) => `${c.patientRefNo},${c.cmsPatientId},${c.gender},${c.age},${c.branch},${c.segment}`)
      .join('\n')}`;
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Geographic Distribution Of Patients.csv');
    document.body.appendChild(link);

    link.click();
  };

  return (
    <>
      <Row className={!props.isEmbedded ? 'pb-5' : 'pb-4'}>
        <div className="button-color" style={{ position: 'relative', zIndex: 999, right: '10px', top: '-16px' }}>
          <Button onClick={exportData} variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="button">
            Export
          </Button>
        </div>
        <Col xs={12} lg={12} className={!props.isEmbedded ? 'px-8 mt-4' : ''}>
          <div ref={mapContainer} className="map-container" />
          {!props.isEmbedded && (
            <div className="map-legend">
              <div>Branch:</div>
              {_.keys(colorMap).map((branch) => (
                <>
                  <div className="marker map-legend-item" style={{ backgroundColor: colorMap[branch] }}></div>{' '}
                  <span className="map-legend-text">{branch}</span>
                </>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default GeographicDistributionOfPatients;
