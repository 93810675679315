import {
    ADMIN_INPROGRESS,
    ADMIN_ERROR,
    ADMIN_DONEPROGRESS,
    GET_PATIENT_DASHBOARD,
    GET_PATIENT_TREATMENTS,
    GET_PATIENT_DOCTORS,
    GET_SEGMENT_FILTER,
    GET_PATIENT_GEOGRAPHIC_DISTRIBUTION,
    GET_PATIENT_DEMOGRAPHIC,
    GET_PATIENT_FIRST_TREATMENT,
    GET_PATIENT_NATIONALITY_DISTRIBUTION,
    GET_PATIENT_OCCUPATION_DISTRIBUTION,
    GET_PATIENT_REFERRAL_SOURCE,
    GET_PATIENT_FREQUENT_DOCTORS,
    GET_PATIENT_MARITAL_STATUS,
} from '../constants/actionsTypes';
import ENVIRONMENT_VARIABLES from '../config';
import axios from "axios"
// import processRequest from "utils/processRequest"

const processRequest = (api, type, objectKey) => {
    try {
        return (dispatch) => {
            dispatch({type: ADMIN_INPROGRESS});
            axios(api).then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type,
                        data: objectKey ? response.data[objectKey] : response.data.data
                    });
                };
                dispatch({type: ADMIN_DONEPROGRESS});
            }).catch((error) => {
                if (error && error.response && (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)) {
                    dispatch({type: ADMIN_ERROR, data: {error_msg: error.response.data.user_msg}});
                } else {
                    dispatch({type: ADMIN_ERROR, data: {error_msg: error.message.toString()}});
                }
                dispatch({type: ADMIN_DONEPROGRESS});
            });
        }
    } catch (error) {
        alert(error.message.toString());
    }
}

export const getPatientDashboard = (chainId) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getPatientDashboard",
        data: { chainId }
    };
    return processRequest(api, GET_PATIENT_DASHBOARD);
};

export const getPatientTreatments = (chainId) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'GET',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Treatments/getTreatments/100/1" + "?chainId=" + chainId
    };
    return processRequest(api, GET_PATIENT_TREATMENTS);
};

export const getPatientDoctors = () => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'GET',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors"
    };
    return processRequest(api, GET_PATIENT_DOCTORS);
};

export const getPatientSegments = (chainId) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getSegmentFilter",
        data: { chainId }
    };
    return processRequest(api, GET_SEGMENT_FILTER);
};

export const getGeoGraphicDistributionOfPatient = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getGeoGraphicDistributionOfPatient",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_GEOGRAPHIC_DISTRIBUTION);
};

export const getPatientDemographic = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/patientDemoGraphic",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_DEMOGRAPHIC);
};

export const getFirstTreatment = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getFirstTreatment",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_FIRST_TREATMENT);
};

export const getNationalityDistribution = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getNationalityDistribution",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_NATIONALITY_DISTRIBUTION);
};

export const getOccupationDistribution = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getOccupationDistribution",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_OCCUPATION_DISTRIBUTION);
};

export const getReferralSource = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getReferralSource",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_REFERRAL_SOURCE);
};

export const getFrequentDoctors = (chainId, treatmentIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getFrequentDoctors",
        data: { chainId, treatmentIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_FREQUENT_DOCTORS);
};

export const getMaritalStatus = (chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate) => {
    const token = "Bearer " + localStorage.getItem('accessToken');
    let api = {
        method: 'POST',
        headers: {'Authorization': token},
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Patients/getMaritalStatus",
        data: { chainId, treatmentIds, doctorIds, segmentIds, clinicIds, filterStartDate, filterEndDate }
    };
    return processRequest(api, GET_PATIENT_MARITAL_STATUS);
};
