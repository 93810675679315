import axios from 'axios';
import {
  ADMIN_INPROGRESS,
  ADMIN_ERROR,
  IS_AUTHENTICATED,
  PASSWORD_RECOVERED,
  LOGOUT,
  GET_USERS_DETAIL,
  GET_ROLES_DETAIL,
  CREATE_USERS,
  UPDATE_USER_DATA,
  GET_DOCTORS_DETAIL,
  GET_DOCTOR_CLINIC_DETAIL,
  GET_KPI_CLINIC,
  GET_CHAINS,
  GET_CLINICS,
  GET_USER_CLINICS_BY_ROLE,
  CREATE_ROLES,
  UPDATE_ROLES,
  USER_DELETED,
  ROLE_DELETED,
} from '../constants/actionsTypes';
import ENVIRONMENT_VARIABLES from '../config';
import { disconnect } from '../socket';
import { createBrowserHistory } from 'history';
import { Routing } from 'routes';
import { LOGIN_URL } from 'RouteConfig';

const history = createBrowserHistory();

export const loginUser = (credentials) => {
  try {
    return async (dispatch) => {
      const loginDetails = {
        userName: credentials.userName,
        password: credentials.password,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      const loginAPi = await fetch(`${ENVIRONMENT_VARIABLES.Base_API_URL}/Oauths/login`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          userName: credentials.userName,
          password: credentials.password,
        }),
      });
      const result = await loginAPi.json();
      if (loginAPi.status === 200) {
        dispatch({
          type: IS_AUTHENTICATED,
          data: { accessToken: result.accessToken },
        });
        history.go('/dashboard');
      } else if (loginAPi.status === 400 || loginAPi.status === 403 || loginAPi.status === 401) {
        dispatch({
          type: ADMIN_ERROR,
          data: { error_msg: result.user_msg },
        });
      } else {
        dispatch({
          type: ADMIN_ERROR,
          data: { error_msg: result.message.toString() },
        });
      }
    };

    //   axios
    //     .post(process.env.REACT_APP_BASE_API_URL + '/Oauths/login', loginDetails)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         dispatch({
    //           type: IS_AUTHENTICATED,
    //           data: { accessToken: response.data.accessToken },
    //         });
    //         history.go('/dashboard');
    //       }
    //     })

    //     .catch((error) => {
    //       if (
    //         error &&
    //         error.response &&
    //         (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
    //       ) {
    //         dispatch({
    //           type: ADMIN_ERROR,
    //           data: { error_msg: error.response.data.user_msg },
    //         });
    //       } else {
    //         dispatch({
    //           type: ADMIN_ERROR,
    //           data: { error_msg: error.message.toString() },
    //         });
    //       }
    //     });
    // };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const forgotPassword = (email) => {
  try {
    return (dispatch) => {
      axios
        .post(ENVIRONMENT_VARIABLES.Base_API_URL + '/Oauths/recovery', { email })
        .then((response) => {
          if (response.status === 200) {
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const changePassword = (userId, uid, password) => {
  try {
    return (dispatch) => {
      axios
        .post(ENVIRONMENT_VARIABLES.Base_API_URL + '/Oauths/recovery/change-password', { userId, uid, password })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: PASSWORD_RECOVERED,
              data: {},
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const logout = () => {
  try {
    disconnect();
    return (dispatch) => {
      dispatch({ type: ADMIN_INPROGRESS });
      localStorage.removeItem('accessToken');
      localStorage.clear();
      dispatch({
        type: LOGOUT,
      });
      history.go('/');
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getDoctors = () => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Doctors/',
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DOCTORS_DETAIL,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getDoctorClinics = (doctorId) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Doctors/clinic/' + Number(doctorId),
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DOCTOR_CLINIC_DETAIL,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getChains = () => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Chains',
        data: {},
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_CHAINS,
              data: response.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getClinics = (chainId) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'POST',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Clinics/',
        data: {
          chainId: chainId,
        },
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_CLINICS,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

// #430: NEW ACTION -> GET ONLY THE OPEN CLINICS
export const getOpenClinicsList = () => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: {
          Authorization: token
        },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Clinics/getOpenClinicsList'
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_CLINICS,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
}

// #298: new action to assign clinic state based on user role -> clinic filter in dr performance report dashboard
export const getUserClinicsByRole = (roleId, chainId, user) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let authToken = {
        headers: { Authorization: token },
      };
      dispatch({ type: ADMIN_INPROGRESS });

      // get clinics data according to role id, then dispatch
      switch (roleId) {
        // chain manager -> use getClinics to get all clinics in a chain
        case 2: {
          if (chainId) {
            let api = Object.assign(
              {
                method: 'POST',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Clinics',
                data: {
                  chainId: chainId,
                },
              },
              authToken
            );
            axios(api)
              .then((response) => {
                if (response.status === 200) {
                  dispatch({
                    type: GET_USER_CLINICS_BY_ROLE,
                    data: [].concat(response.data.data),
                  });
                }
              })
              .catch((error) => {
                if (
                  error &&
                  error.response &&
                  (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
                ) {
                  dispatch({
                    type: ADMIN_ERROR,
                    data: { error_msg: error.response.data.user_msg },
                  });
                } else {
                  dispatch({
                    type: ADMIN_ERROR,
                    data: { error_msg: error.message.toString() },
                  });
                }
              });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: 'Empty chainId for Chain Manager role (roleId: 2)' },
            });
          }
        }
        // clinic manager -> use info within adminuser state
        case 3: {
          if (user.clinic) {
            dispatch({
              type: GET_USER_CLINICS_BY_ROLE,
              data: [].concat(user.clinic),
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: 'Empty user.clinic for Clinic Manager role (roleId: 3' },
            });
          }
        }
        // doctor
        case 4: {
          if (user.doctor) {
            let api = Object.assign(
              {
                method: 'GET',
                url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Doctors/clinic/' + Number(user.doctor._id),
              },
              authToken
            );
            axios(api)
              .then((response) => {
                if (response.status === 200) {
                  dispatch({
                    type: GET_USER_CLINICS_BY_ROLE,
                    data: [].concat(response.data.data.clinics),
                  });
                }
              })
              .catch((error) => {
                if (
                  error &&
                  error.response &&
                  (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
                ) {
                  dispatch({
                    type: ADMIN_ERROR,
                    data: { error_msg: error.response.data.user_msg },
                  });
                } else {
                  dispatch({
                    type: ADMIN_ERROR,
                    data: { error_msg: error.message.toString() },
                  });
                }
              });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: 'No user.doctor._id for Doctot role (roleId: 4)' },
            });
          }
        }
      }
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getKpiClinics = () => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Clinics/KPI/',
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_KPI_CLINIC,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateKpiClinic = (detail) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'PUT',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Clinics/',
        data: detail,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_KPI_CLINIC,
              data: response.data.data,
            });
            history.go(Routing.KpiClinic.path);
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const createUsers = (userDetail) => {
  try {
    return (dispatch) => {
      const userData = {
        userName: userDetail.userName,
        fullName: userDetail.userFullName,
        email: userDetail.userEmail,
        roleId: userDetail.userRole._id,
        chainId: userDetail.userChain ? Number(userDetail.userChain) : 0,
        doctorId: userDetail.userDoctor ? Number(userDetail.userDoctor._id) : 0,
        clinicId: userDetail.userClinic ? Number(userDetail.userClinic) : 0,
      };
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'POST',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Oauths/create',
        data: userData,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CREATE_USERS,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateUser = (userDetail) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      const userData = {
        _id: userDetail.userId,
        fullName: userDetail.userFullName,
        email: userDetail.userEmail,
        roleId: Number(userDetail.userRole),
        isActive: userDetail.userStatus == 'false' ? false : true,
      };
      let api = {
        method: 'PUT',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Oauths/',
        data: userData,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_USER_DATA,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getusers = () => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Oauths/',
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USERS_DETAIL,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteUsers = (id) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'DELETE',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Oauths/',
        data: { _ids: [id] },
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: USER_DELETED,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getroles = () => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'GET',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Roles/',
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ROLES_DETAIL,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const createRole = (roleData) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      const role_data = {
        name: roleData.role,
        description: roleData.description,
        // "landingPage": roleData.landingPage,
        roles: roleData.roles,
      };
      let api = {
        method: 'POST',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Roles/create',
        data: role_data,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: CREATE_ROLES,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const updateRole = (roleData) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      const role_data = {
        _id: roleData.roleId,
        name: roleData.role,
        description: roleData.description,
      };
      let api = {
        method: 'PUT',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Roles',
        data: role_data,
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_ROLES,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const deleteRole = (id) => {
  try {
    return (dispatch) => {
      const token = 'Bearer ' + localStorage.getItem('accessToken');
      let api = {
        method: 'DELETE',
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + '/Roles/',
        data: { _ids: [id] },
      };
      dispatch({ type: ADMIN_INPROGRESS });
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ROLE_DELETED,
              data: response.data.data,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 || error.response.status === 403 || error.response.status === 401)
          ) {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: ADMIN_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};
