import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Form, Button, Breadcrumb,
    InputGroup } from 'react-bootstrap';

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment-timezone";

import { faCalendarAlt } from "@heroicons/react/solid";

import * as authAction from "actions/authActions";
import ENVIRONMENT_VARIABLES from "../../config"

import Loader from "../Loader";
import './style.css'
import { object } from "underscore";
import swal from "sweetalert";

const ClinicConfiguration = (props) => {
    const [selectedClinic, setSelectedClinic] = useState('');
    const [autoPopulateField, setAutoPopulateField] = useState(false);
    const [kpiClinic, setKpiClinic] = useState('');
    const [clinic, setClinic] = useState([]);
    const [effectiveDate, setEffectiveDate] = React.useState("");

    const [operatingHours, setOperatingHours] = React.useState([]);
    const [dirtyOperatingHours, setDirtyOperatingHours] = React.useState([]);

    const [numberOfChairs, setNumberOfChairs] = React.useState([]);
    const [dirtyNumberOfChairs, setDirtyNumberOfChairs] = React.useState([]);

    const [treatmentCycles, setTreatmentCycles] = React.useState([]);
    const [dirtyTreatmentCycles, setDirtyTreatmentCycles] = React.useState([]);

    const [treatmentRevenuePerHour, setTreatmentRevenuePerHour] = React.useState([]);
    const [dirtyTreatmentRevenuePerHour, setDirtyTreatmentRevenuePerHour] = React.useState([]);

    // #286: states for all 5 costs
    // material
    const [materialCostItems, setMaterialCostItems] = React.useState([]);
    const [dirtyMaterialCostItems, setDirtyMaterialCostItems] = React.useState([]);
    // misc
    const [miscCostItems, setMiscCostItems] = React.useState([]);
    const [dirtyMiscCostItems, setDirtyMiscCostItems] = React.useState([]);
    // overhead
    const [overheadCostItems, setOverheadCostItems] = React.useState([]);
    const [dirtyOverheadCostItems, setDirtyOverheadCostItems] = React.useState([]);
    // dentist commission
    const [dentistCommissionItems, setDentistCommissionItems] = React.useState([]);
    const [dirtyDentistCommissionItems, setDirtyDentistCommissionItems] = React.useState([]);
    // payment mmode
    const [paymentModeItems, setPaymentModeItems] = React.useState([]);
    const [dirtyPaymentModeItems, setDirtyPaymentModeItems] = React.useState([]);

    const [inProgressCalls, setInProgressCalls] = React.useState(0);

    const [allTreatments, setAllTreatments] = useState([]);

    // #271: state to store configAvgDurationDateRangeMonth and configAvgDurationDefault from backend
    const [configAvgDurationDateRangeMonth, setConfigAvgDurationDateRangeMonth] = useState();
    const [configAvgDurationDefault, setConfigAvgDurationDefault] = useState();

    const clinicValue = ['_id', 'isActive', 'isSync', 'createdAt', 'updatedAt'];

    const history = useHistory();
    const redirectUrl = (url) => {
        history.push(url);
    };

    useEffect(() => {
        props.actions.adminAction.getKpiClinics();
    },[]);

    const adminAuthReducer = useSelector((state) => ({
        kpiClinic: state.adminAuthReducer.kpiClinic,
        isLoading: state.adminAuthReducer.loading
    }));
    useEffect(() => {
        if(adminAuthReducer.kpiClinic) {
            setClinic(adminAuthReducer.kpiClinic.map(u => ({ ...u })))
        }
    }, [adminAuthReducer.kpiClinic]);

    // get all treatments
    useEffect(() => {
        const token = "Bearer " + localStorage.getItem("accessToken")
        // get all treatment cycles
        axios.get(
            `${ENVIRONMENT_VARIABLES.Base_API_URL}/Treatments/getTreatments/200/1`,
            { headers: { Authorization: token } }
        ).then(result => {
            setAllTreatments(result.data.data?.map(elem => {
                return {
                    name: elem.treatmentName
                }
            }) || []);
        }).catch(err => {
            console.log('Error: ', err);
        });
        // #271: call backend API to get configAvgDurationDateRangeMonth and configAvgDurationDefault
        axios.get(
            `${ENVIRONMENT_VARIABLES.Base_API_URL}/Treatments/getConfigDateRangeMonth`,
            { headers: { Authorization: token } }
        ).then(result => {
            setConfigAvgDurationDateRangeMonth(result.configDateRangeMonth || 6);
            setConfigAvgDurationDefault(result.configDefault || 0);
        }).catch(err => {
            console.log("getConfigDateRangeMonth error: ", err);
        })
    }, []);

    // on clinic change
    const onChangeClinicValue = (value) => {
        setAutoPopulateField(true);
        setSelectedClinic(clinic.filter(u => u._id === Number(value))[0]);
        setKpiClinic(kpiClinic);
    };

    // daily hours
    useEffect(() => {
        let tempObj = {
            openingHours: 0,
            openingMinutes: 0,
            closingHours: 0,
            closingMinutes: 0,
            bStartHours: 0,
            bStartMinutes: 0,
            bEndHours: 0,
            bEndMinutes: 0
        }
        let tempOperatingHours = [
            Object.assign({day: 'Monday'}, tempObj),
            Object.assign({day: 'Tuesday'}, tempObj),
            Object.assign({day: 'Wednesday'}, tempObj),
            Object.assign({day: 'Thursday'}, tempObj),
            Object.assign({day: 'Friday'}, tempObj),
            Object.assign({day: 'Saturday'}, tempObj),
            Object.assign({day: 'Sunday'}, tempObj)
        ];
        // get and set daily hours table
        // for now using dummy data
        if (selectedClinic != '') {
            // call api and get data
            const token = "Bearer " + localStorage.getItem("accessToken")
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/DailyOperatingHours/getDailyOperatingHours/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    // setOperatingHours([]);
                    setDirtyOperatingHours(JSON.parse(JSON.stringify(tempOperatingHours)));
                } else {
                    setEffectiveDate(
                        moment(result.data[0]['effectiveDate'])
                    );
                    setOperatingHours(JSON.parse(JSON.stringify(result.data)));
                    setDirtyOperatingHours(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });
        } else {
            // setOperatingHours([]);
            setDirtyOperatingHours(JSON.parse(JSON.stringify(tempOperatingHours)));
        }
    }, [selectedClinic]);

    // get and set costs
    // get and set chairs 
    useEffect(() => {
        // #286: temp data for all 5 costs
        // material
        let tempMaterialCostItems = [
            {
                materialName: '',
                materialCost: 0,
                effectiveDate: moment().format('MM/DD/YYYY')
            },
        ];
        // misc
        let tempMiscCostItems = [
            {
                miscName: '',
                miscCost: 0,
                effectiveDate: moment().format('MM/DD/YYYY')
            },
        ];
        // overhead
        let tempOverheadCostItems = [
            {
                overheadName: '',
                overheadCost: 0,
                effectiveDate: moment().format('MM/DD/YYYY')
            },
        ];
        // dentist commission
        let tempDentistCommissionItems = [
            {
                dentistCommissionName: '',
                dentistCommission: 0,
                effectiveDate: moment().format('MM/DD/YYYY')
            },
        ];
        // payment disc
        let tempPaymentModeDiscount = [
            {
                paymentModeName: '',
                paymentModeDiscount: 0,
                effectiveDate: moment().format('MM/DD/YYYY')
            },
        ];
        let tempChairs = [
            {
                effectiveDate: moment().format('MM/DD/YYYY'),
                branch: selectedClinic.clinicId,
                chairs: 0
            }
        ];
        let tempCycles = [
            {
                name: '',
                avgVisits: 1
            }
        ];
        let tempRevenuePerHour = [
            {
                treatment: '',
                revenuePerHour: 0
            }
        ];

        // if a clinic is selected, get existing data if any or use temp data
        if (selectedClinic != '') {
            const token = "Bearer " + localStorage.getItem("accessToken")

            // #286: all 5 costs
            // material
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/Costs/getMaterialCost/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setMaterialCostItems(JSON.parse(JSON.stringify(tempMaterialCostItems)));
                    setDirtyMaterialCostItems(JSON.parse(JSON.stringify(tempMaterialCostItems)));
                } else {
                    setMaterialCostItems(JSON.parse(JSON.stringify(result.data)));
                    setDirtyMaterialCostItems(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });
            // misc
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/Costs/getMiscCost/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setMiscCostItems(JSON.parse(JSON.stringify(tempMiscCostItems)));
                    setDirtyMiscCostItems(JSON.parse(JSON.stringify(tempMiscCostItems)));
                } else {
                    setMiscCostItems(JSON.parse(JSON.stringify(result.data)));
                    setDirtyMiscCostItems(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });
            // overhead
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/Costs/getOverheadCost/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setOverheadCostItems(JSON.parse(JSON.stringify(tempOverheadCostItems)));
                    setDirtyOverheadCostItems(JSON.parse(JSON.stringify(tempOverheadCostItems)));
                } else {
                    setOverheadCostItems(JSON.parse(JSON.stringify(result.data)));
                    setDirtyOverheadCostItems(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });
            // dentist commission
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/Costs/getDentistCommission/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setDentistCommissionItems(JSON.parse(JSON.stringify(tempDentistCommissionItems)));
                    setDirtyDentistCommissionItems(JSON.parse(JSON.stringify(tempDentistCommissionItems)));
                } else {
                    setDentistCommissionItems(JSON.parse(JSON.stringify(result.data)));
                    setDirtyDentistCommissionItems(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });
            // payment mode discount
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/Costs/getPaymentModeDiscount/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setPaymentModeItems(JSON.parse(JSON.stringify(tempPaymentModeDiscount)));
                    setDirtyPaymentModeItems(JSON.parse(JSON.stringify(tempPaymentModeDiscount)));
                } else {
                    setPaymentModeItems(JSON.parse(JSON.stringify(result.data)));
                    setDirtyPaymentModeItems(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });

            // daily chair counts
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/DailyChairCounts/getDailyChairCounts/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setNumberOfChairs(JSON.parse(JSON.stringify(tempChairs)));
                    setDirtyNumberOfChairs(JSON.parse(JSON.stringify(tempChairs)));
                } else {
                    setNumberOfChairs(JSON.parse(JSON.stringify(result.data)));
                    setDirtyNumberOfChairs(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });

            // treatment cycle
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/TreatmentCycle/getTreatmentCycle/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setTreatmentCycles([]);
                    setDirtyTreatmentCycles(JSON.parse(JSON.stringify(allTreatments.map(treatment => {
                        return {
                            name: treatment.name,
                            avgVisits: 1
                        };
                    }))));
                } else {
                    setTreatmentCycles(JSON.parse(JSON.stringify(result.data)));
                    setDirtyTreatmentCycles(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });

            // treatment rev/hour
            axios.get(
                `${ENVIRONMENT_VARIABLES.Base_API_URL}/TreatmentRevenuePerHour/getTreatmentRevenuePerHour/${selectedClinic._id}`,
                { headers: { Authorization: token } }
            ).then(result => {
                if (result.data.length < 1) {
                    setTreatmentRevenuePerHour([]);
                    setDirtyTreatmentRevenuePerHour(JSON.parse(JSON.stringify(tempRevenuePerHour)));
                } else {
                    setTreatmentRevenuePerHour(JSON.parse(JSON.stringify(result.data)));
                    setDirtyTreatmentRevenuePerHour(JSON.parse(JSON.stringify(result.data)));
                }
            }).catch(err => {
                console.log('Error: ', err);
            });
        // else if no clinic is selected:  set all temp values
        } else {
            // #286: all 5 costs
            // material
            setMaterialCostItems([]);
            setDirtyMaterialCostItems(JSON.parse(JSON.stringify(tempMaterialCostItems)));
            // misc
            setMiscCostItems([]);
            setDirtyMiscCostItems(JSON.parse(JSON.stringify(tempMiscCostItems)));
            // overhead
            setOverheadCostItems([]);
            setDirtyOverheadCostItems(JSON.parse(JSON.stringify(tempOverheadCostItems)));
            //  dentist commission
            setDentistCommissionItems([]);
            setDirtyDentistCommissionItems(JSON.parse(JSON.stringify(tempDentistCommissionItems)));
            //  payment mode
            setPaymentModeItems([]);
            setDirtyPaymentModeItems(JSON.parse(JSON.stringify(tempPaymentModeDiscount)));

            setNumberOfChairs([]);
            setDirtyNumberOfChairs(JSON.parse(JSON.stringify(tempChairs)));

            setTreatmentCycles([]);
            setDirtyTreatmentCycles(JSON.parse(JSON.stringify(tempCycles)));

            setTreatmentRevenuePerHour([]);
            setDirtyTreatmentRevenuePerHour(JSON.parse(JSON.stringify(tempRevenuePerHour)));
        }
    }, [selectedClinic]);

    // #286: add new entry function for all 5 costs
    // material
    function addNewMaterialItem() {
        let tempArr = Object.assign([], dirtyMaterialCostItems);
        tempArr.push({
            materialName: '',
            materialCost: 0,
            effectiveDate: moment().format('MM/DD/YYYY')
        });
        setDirtyMaterialCostItems(tempArr);
    }
    // misc
    function addNewMiscItem() {
        let tempArr = Object.assign([], dirtyMiscCostItems);
        tempArr.push({
            miscName: '',
            miscCost: 0,
            effectiveDate: moment().format('MM/DD/YYYY')
        });
        setDirtyMiscCostItems(tempArr);
    }
    // overhead
    function addNewOverheadItem() {
        let tempArr = Object.assign([], dirtyOverheadCostItems);
        tempArr.push({
            overheadName: '',
            overheadCost: 0,
            effectiveDate: moment().format('MM/DD/YYYY')
        });
        setDirtyOverheadCostItems(tempArr);
    }
    // dentist commission
    function addNewDentistCommissionItem() {
        let tempArr = Object.assign([], dirtyDentistCommissionItems);
        tempArr.push({
            dentistCommissionName: '',
            dentistCommission: 0,
            effectiveDate: moment().format('MM/DD/YYYY')
        });
        setDirtyDentistCommissionItems(tempArr);
    }
    // payment mode discount
    function addNewPaymentModeItem() {
        let tempArr = Object.assign([], dirtyPaymentModeItems);
        tempArr.push({
            paymentModeName: '',
            paymentModeDiscount: 0,
            effectiveDate: moment().format('MM/DD/YYYY')
        });
        setDirtyPaymentModeItems(tempArr);
    }

    function addNewChairsEntry() {
        let tempArr = Object.assign([], dirtyNumberOfChairs);
        tempArr.push({
            effectiveDate: moment().format('MM/DD/YYYY'),
            branch: selectedClinic.clinicId,
            chairs: 0
        });
        setDirtyNumberOfChairs(tempArr);
    }

    function addNewTreatmentEntry() {
        let tempArr = Object.assign([], dirtyTreatmentCycles);
        tempArr.push({
            name: '',
            avgVisits: 1
        })
        setDirtyTreatmentCycles(tempArr);
    }

    function addNewTreatmentRevenuePerHourEntry() {
        let tempArr = Object.assign([], dirtyTreatmentRevenuePerHour);
        tempArr.push({
            treatment: '',
            revenuePerHour: 0
        })
        setDirtyTreatmentRevenuePerHour(tempArr);
    }

    function changeOpHours(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyOperatingHours);
        tempArr[ind][changedKey] = parseInt(newValue, 10);
        setDirtyOperatingHours(tempArr);
    }

    // #286: change entry function for all 5 costs
    // material
    function changeMaterialItem(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyMaterialCostItems);
        if (changedKey == 'materialCost') {
            tempArr[ind][changedKey] = parseFloat(newValue);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyMaterialCostItems(tempArr);
    }
    // misc
    function changeMiscItem(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyMiscCostItems);
        if (changedKey == 'miscCost') {
            tempArr[ind][changedKey] = parseFloat(newValue);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyMiscCostItems(tempArr);
    }
    // overhead
    function changeOverheadItem(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyOverheadCostItems);
        if (changedKey == 'overheadCost') {
            tempArr[ind][changedKey] = parseFloat(newValue);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyOverheadCostItems(tempArr);
    }
    // dentist commission
    function changeDentistCommissionItem(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyDentistCommissionItems);
        if (changedKey == 'dentistCommission') {
            tempArr[ind][changedKey] = parseFloat(newValue);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyDentistCommissionItems(tempArr);
    }
    // payment mode discount
    function changePaymentModeItem(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyPaymentModeItems);
        if (changedKey == 'paymentModeDiscount') {
            tempArr[ind][changedKey] = parseFloat(newValue);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyPaymentModeItems(tempArr);
    }

    function changeNumberOfChairs(ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyNumberOfChairs);
        if (changedKey == 'chairs') {
            tempArr[ind][changedKey] = parseInt(newValue, 10);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyNumberOfChairs(tempArr);
    }

    function changeTreatmentCycle(item, ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyTreatmentCycles);
        if (changedKey != 'name') {
            tempArr[ind][changedKey] = parseInt(newValue, 10);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyTreatmentCycles(tempArr);
    }

    function changeTreatmentRevenuePerHour(item, ind, changedKey, newValue) {
        let tempArr = Object.assign([], dirtyTreatmentRevenuePerHour);
        if (changedKey != 'treatment') {
            tempArr[ind][changedKey] = parseFloat(newValue);
        } else {
            tempArr[ind][changedKey] = newValue;
        }
        setDirtyTreatmentRevenuePerHour(tempArr);
    }

    // #286: check if data is dirty for all 5 costs
    // material
    function isMaterialDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (materialCostItems.length != dirtyMaterialCostItems.length) {
            isDirty = true;
        } else {
            for (const data of dirtyMaterialCostItems) {
                for (const key of Object.keys(data)) {
                    if (key == 'effectiveDate') {
                        if (moment(data[key]).valueOf() != moment(materialCostItems[ind][key]).valueOf()) {
                            isDirty = true;
                            break;
                        }
                    } else {
                        if (data[key] != materialCostItems[ind][key]) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }
    // misc
    function isMiscDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (miscCostItems.length != dirtyMiscCostItems.length) {
            isDirty = true;
        } else {
            // adjust this
            for (const data of dirtyMiscCostItems) {
                for (const key of Object.keys(data)) {
                    if (key == 'effectiveDate') {
                        // adjust this
                        if (moment(data[key]).valueOf() != moment(miscCostItems[ind][key]).valueOf()) {
                            isDirty = true;
                            break;
                        }
                    } else {
                        // adjust this
                        if (data[key] != miscCostItems[ind][key]) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }
    // overhead
    function isOverheadDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (overheadCostItems.length != dirtyOverheadCostItems.length) {
            isDirty = true;
        } else {
            // adjust this
            for (const data of dirtyOverheadCostItems) {
                for (const key of Object.keys(data)) {
                    if (key == 'effectiveDate') {
                        // adjust this
                        if (moment(data[key]).valueOf() != moment(overheadCostItems[ind][key]).valueOf()) {
                            isDirty = true;
                            break;
                        }
                    } else {
                        // adjust this
                        if (data[key] != overheadCostItems[ind][key]) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }
    // dentist commission
    function isDentistCommissionDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (dentistCommissionItems.length != dirtyDentistCommissionItems.length) {
            isDirty = true;
        } else {
            // adjust this
            for (const data of dirtyDentistCommissionItems) {
                for (const key of Object.keys(data)) {
                    if (key == 'effectiveDate') {
                        // adjust this
                        if (moment(data[key]).valueOf() != moment(dentistCommissionItems[ind][key]).valueOf()) {
                            isDirty = true;
                            break;
                        }
                    } else {
                        // adjust this
                        if (data[key] != dentistCommissionItems[ind][key]) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }
    // payment mode discount
    function isPaymentModeDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (paymentModeItems.length != dirtyPaymentModeItems.length) {
            isDirty = true;
        } else {
            // adjust this
            for (const data of dirtyPaymentModeItems) {
                for (const key of Object.keys(data)) {
                    if (key == 'effectiveDate') {
                        // adjust this
                        if (moment(data[key]).valueOf() != moment(paymentModeItems[ind][key]).valueOf()) {
                            isDirty = true;
                            break;
                        }
                    } else {
                        // adjust this
                        if (data[key] != paymentModeItems[ind][key]) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }

    function isOpHrDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (operatingHours.length != dirtyOperatingHours.length) {
            isDirty = true;
        } else {
            for (const data of dirtyOperatingHours) {
                for (const key of Object.keys(data)) {
                    if (data[key] != operatingHours[ind][key]) {
                        isDirty = true;
                        break;
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }

    function isChairsDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (numberOfChairs.length != dirtyNumberOfChairs.length) {
            isDirty = true;
        } else {
            for (const data of dirtyNumberOfChairs) {
                for (const key of Object.keys(data)) {
                    if (key == 'effectiveDate') {
                        if (moment(data[key]).valueOf() != moment(numberOfChairs[ind][key]).valueOf()) {
                            isDirty = true;
                            break;
                        }
                    } else {
                        if (data[key] != numberOfChairs[ind][key]) {
                            isDirty = true;
                            break;
                        }
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }

    function isTreatmentCycleDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (treatmentCycles.length != dirtyTreatmentCycles.length) {
            isDirty = true;
        } else {
            for (const data of dirtyTreatmentCycles) {
                for (const key of Object.keys(data)) {
                    if (data[key] != treatmentCycles[ind][key]) {
                        isDirty = true;
                        break;
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }

    function isTreatmentRevenuePerHourDataDirty() {
        let isDirty = false;
        let ind = 0;
        if (treatmentRevenuePerHour.length != dirtyTreatmentRevenuePerHour.length) {
            isDirty = true;
        } else {
            for (const data of dirtyTreatmentRevenuePerHour) {
                for (const key of Object.keys(data)) {
                    if (data[key] != treatmentRevenuePerHour[ind][key]) {
                        isDirty = true;
                        break;
                    }
                }
                if (isDirty) {
                    break;
                }
                ind++;
            }
        }
        return isDirty;
    }

    // #271: Call API to automatically generate average duration and update medtrik.TreatmentCategories.avgDuration with the new average values
    function generateAvgDuration() {
        const token = "Bearer " + localStorage.getItem("accessToken");
        setInProgressCalls(inProgressCalls + 1);
        axios.get(
            `${ENVIRONMENT_VARIABLES.Base_API_URL}/Treatments/getNewAvgDurationPerTreatment`,
            { headers: { Authorization: token } }
        )
        .then(result => {
            // toast.success( 'Average duration for each treatment is updated successfully.', {
            //     position: "top-right",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            // })
            swal({
                title: "Success!",
                text: "Average duration for each treatment is updated successfully.",
                icon: "success"
            });
            setInProgressCalls(inProgressCalls - 1);
        })
        .catch(err => {
            // toast.error( err.response.data.user_msg , {
            //     position: "top-right",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: false,
            //     draggable: true,
            //     progress: undefined,
            // });
            swal({
                title: "Error!",
                text: err.response.data.user_msg,
                icon: "error"
            });
            setInProgressCalls(inProgressCalls - 1);
            console.log('Error: ', err);
        })
    }

    function saveClinicConfig() {
        if (selectedClinic == '' && effectiveDate == '') {
            // show error
            toast.error( 'Please select Clinic / Effective date.' , {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        } else {
            // check if daily operating hours data is dirty
            if (effectiveDate != '' && selectedClinic != '' &&
                (isOpHrDataDirty() || effectiveDate != moment(operatingHours[0]['effectiveDate']))) {
                let _data = {
                    clinicId: selectedClinic._id,
                    weeklyTime: dirtyOperatingHours,
                    effectiveDate: moment(effectiveDate).toISOString()
                };
                const token = "Bearer " + localStorage.getItem("accessToken")
                setInProgressCalls(inProgressCalls + 1);
                axios.post(
                    ENVIRONMENT_VARIABLES.Base_API_URL + "/DailyOperatingHours/DailyOperatingHours",
                    _data,
                    {
                        headers: { Authorization: token }
                    }
                )
                    .then(result => {
                        toast.success( 'Daily operating hours data updated successfully.' , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setOperatingHours(result.data);
                        setDirtyOperatingHours(result.data);
                        setInProgressCalls(inProgressCalls - 1);
                    })
                    .catch(err => {
                        toast.error( err.response.data.user_msg , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setInProgressCalls(inProgressCalls - 1);
                        console.log('Error: ', err);
                    });
            }
            // #286: check if cost data are dirty, if yes: call create entry API
            // material
            if (selectedClinic != '' && isMaterialDataDirty()) {
                let tempArr = JSON.parse(JSON.stringify(dirtyMaterialCostItems));

                let isMaterialValid = true;
                for (const ind in tempArr) {
                    if (tempArr[ind]['materialName']=='') {
                        toast.error( 'Material Name should not be empty.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        isMaterialValid=false;
                        break;
                    } 
                    if (tempArr[ind]['materialCost']<=0) {
                        toast.error( 'Material Cost should be more than 0.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        isMaterialValid=false;
                        break;
                    }
                }

                if (isMaterialValid) {
                    tempArr.forEach(elem => {
                        elem.effectiveDate = moment(elem.effectiveDate).toISOString()
                    });
                    let _data = {
                        clinicId: selectedClinic._id,
                        materialCostData: dirtyMaterialCostItems,
                        effectiveDate: moment(effectiveDate).toISOString()
                    };
                    const token = "Bearer " + localStorage.getItem("accessToken")
                    setInProgressCalls(inProgressCalls + 1);
                    axios.post(
                        ENVIRONMENT_VARIABLES.Base_API_URL + "/Costs/createMaterialCostEntry",
                        _data,
                        {
                            headers: { Authorization: token }
                        }
                    )
                        .then(result => {
                            toast.success( 'Material cost data updated successfully.' , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setMaterialCostItems(_data);
                            setDirtyMaterialCostItems(result.data);
                            setInProgressCalls(inProgressCalls - 1);
                        })
                        .catch(err => {
                            toast.error( err.response.data.user_msg , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setInProgressCalls(inProgressCalls - 1);
                            console.log('Error: ', err);
                        });
                }
            }
            // misc
            if (selectedClinic != '' && isMiscDataDirty()) {
                let tempArr = JSON.parse(JSON.stringify(dirtyMiscCostItems));

                let isMiscValid = true;
                for (const ind in tempArr) {
                    // adjust this

                    if (tempArr[ind]['miscName']=='') {
                        // adjust this
                        toast.error( 'Misc Name should not be empty.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isMiscValid=false;
                        break;
                    } 
                    // adjust this
                    if (tempArr[ind]['miscCost']<=0) {
                        // adjust this
                        toast.error( 'Misc Cost should be more than 0.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isMiscValid=false;
                        break;
                    }
                }

                if (isMiscValid) {
                    tempArr.forEach(elem => {
                        elem.effectiveDate = moment(elem.effectiveDate).toISOString()
                    });
                    let _data = {
                        clinicId: selectedClinic._id,
                        miscCostData: dirtyMiscCostItems,
                        effectiveDate: moment(effectiveDate).toISOString()
                    };
                    const token = "Bearer " + localStorage.getItem("accessToken")
                    setInProgressCalls(inProgressCalls + 1);
                    axios.post(
                        ENVIRONMENT_VARIABLES.Base_API_URL + "/Costs/createMiscCostEntry",
                        _data,
                        {
                            headers: { Authorization: token }
                        }
                    )
                        .then(result => {
                            toast.success( 'Misc cost data updated successfully.' , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setMiscCostItems(_data);
                            setDirtyMiscCostItems(result.data);
                            setInProgressCalls(inProgressCalls - 1);
                        })
                        .catch(err => {
                            toast.error( err.response.data.user_msg , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setInProgressCalls(inProgressCalls - 1);
                            console.log('Error: ', err);
                        });    
                }
            }
            // overhead
            if (selectedClinic != '' && isOverheadDataDirty()) {
                let tempArr = JSON.parse(JSON.stringify(dirtyOverheadCostItems));

                let isOverhadValid = true;
                for (const ind in tempArr) {
                    // adjust this
                    if (tempArr[ind]['overheadName']=='') {
                        // adjust this
                        toast.error( 'Overhead Name should not be empty.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isOverhadValid=false;
                        break;
                    } 
                    // adjust this
                    if (tempArr[ind]['overheadCost']<=0) {
                        // adjust this
                        toast.error( 'Overhead Cost should be more than 0.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isOverhadValid=false;
                        break;
                    }
                }

                if (isOverhadValid) {
                    tempArr.forEach(elem => {
                        elem.effectiveDate = moment(elem.effectiveDate).toISOString()
                    });
                    let _data = {
                        clinicId: selectedClinic._id,
                        overheadCostData: dirtyOverheadCostItems,
                        effectiveDate: moment(effectiveDate).toISOString()
                    };
                    const token = "Bearer " + localStorage.getItem("accessToken")
                    setInProgressCalls(inProgressCalls + 1);
                    axios.post(
                        ENVIRONMENT_VARIABLES.Base_API_URL + "/Costs/createOverheadCostEntry",
                        _data,
                        {
                            headers: { Authorization: token }
                        }
                    )
                        .then(result => {
                            toast.success( 'Overhead cost data updated successfully.' , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setOverheadCostItems(_data);
                            setDirtyOverheadCostItems(result.data);
                            setInProgressCalls(inProgressCalls - 1);
                        })
                        .catch(err => {
                            toast.error( err.response.data.user_msg , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setInProgressCalls(inProgressCalls - 1);
                            console.log('Error: ', err);
                        });
                }
            }
            // dentist commission
            if (selectedClinic != '' && isDentistCommissionDataDirty()) {
                let tempArr = JSON.parse(JSON.stringify(dirtyDentistCommissionItems));

                let isDentistCommissionValid = true;
                for (const ind in tempArr) {
                    // adjust this
                    if (tempArr[ind]['dentistCommissionName']=='') {
                        // adjust this
                        toast.error( 'Dentist Commission Name should not be empty.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isDentistCommissionValid=false;
                        break;
                    } 
                    // adjust this
                    if (tempArr[ind]['dentistCommission']<=0) {
                        // adjust this
                        toast.error( 'Dentist Commission Cost should be more than 0.', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isDentistCommissionValid=false;
                        break;
                    }
                }

                if (isDentistCommissionValid) {
                    tempArr.forEach(elem => {
                        elem.effectiveDate = moment(elem.effectiveDate).toISOString()
                    });
                    let _data = {
                        clinicId: selectedClinic._id,
                        dentistCommissionData: dirtyDentistCommissionItems,
                        effectiveDate: moment(effectiveDate).toISOString()
                    };
                    const token = "Bearer " + localStorage.getItem("accessToken")
                    setInProgressCalls(inProgressCalls + 1);
                    axios.post(
                        ENVIRONMENT_VARIABLES.Base_API_URL + "/Costs/createDentistCommissionEntry",
                        _data,
                        {
                            headers: { Authorization: token }
                        }
                    )
                        .then(result => {
                            toast.success( 'Dentist commission data updated successfully.' , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setDentistCommissionItems(_data);
                            setDirtyDentistCommissionItems(result.data);
                            setInProgressCalls(inProgressCalls - 1);
                        })
                        .catch(err => {
                            toast.error( err.response.data.user_msg , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setInProgressCalls(inProgressCalls - 1);
                            console.log('Error: ', err);
                        });
                }
            }
            // payment mode discount
            if (selectedClinic != '' && isPaymentModeDataDirty()) {
                let tempArr = JSON.parse(JSON.stringify(dirtyPaymentModeItems));

                let isPaymentModeValid = true;
                for (const ind in tempArr) {
                    // adjust this
                    if (tempArr[ind]['paymentModeName']=='') {
                        // adjust this
                        toast.error( 'Payment Mode Name should not be empty.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isPaymentModeValid=false;
                        break;
                    } 
                    // adjust this
                    if (tempArr[ind]['paymentModeDiscount']<=0) {
                        // adjust this
                        toast.error( 'Payment Mode Discount should be more than 0.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        // adjust this
                        isPaymentModeValid=false;
                        break;
                    }
                }

                if (isPaymentModeValid) {
                    tempArr.forEach(elem => {
                        elem.effectiveDate = moment(elem.effectiveDate).toISOString()
                    });
                    let _data = {
                        clinicId: selectedClinic._id,
                        paymentDiscountData: dirtyPaymentModeItems,
                        effectiveDate: moment(effectiveDate).toISOString()
                    };
                    const token = "Bearer " + localStorage.getItem("accessToken")
                    setInProgressCalls(inProgressCalls + 1);
                    axios.post(
                        ENVIRONMENT_VARIABLES.Base_API_URL + "/Costs/createPaymentModeDiscountEntry",
                        _data,
                        {
                            headers: { Authorization: token }
                        }
                    )
                        .then(result => {
                            toast.success( 'Payment mode discount data updated successfully.' , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setPaymentModeItems(_data);
                            setDirtyPaymentModeItems(result.data);
                            setInProgressCalls(inProgressCalls - 1);
                        })
                        .catch(err => {
                            toast.error( err.response.data.user_msg , {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            });
                            setInProgressCalls(inProgressCalls - 1);
                            console.log('Error: ', err);
                        });
    
                }
            }
            // check if chair data is dirty
            if (selectedClinic != '' && isChairsDataDirty()) {
                let tempArr = JSON.parse(JSON.stringify(dirtyNumberOfChairs));
                tempArr.forEach(elem => {
                    elem.effectiveDate = moment(elem.effectiveDate).toISOString()
                });
                let _data = {
                    clinicId: selectedClinic._id,
                    chairData: tempArr,
                    effectiveDate: moment(effectiveDate).toISOString()
                };
                const token = "Bearer " + localStorage.getItem("accessToken")
                setInProgressCalls(inProgressCalls + 1);
                axios.post(
                    ENVIRONMENT_VARIABLES.Base_API_URL + "/DailyChairCounts/DailyChairCounts",
                    _data,
                    {
                        headers: { Authorization: token }
                    }
                )
                    .then(result => {
                        toast.success( 'Number of Chairs data updated successfully.' , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setNumberOfChairs(result.data);
                        setDirtyNumberOfChairs(result.data);
                        setInProgressCalls(inProgressCalls - 1);
                    })
                    .catch(err => {
                        toast.error( err.response.data.user_msg , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setInProgressCalls(inProgressCalls - 1);
                        console.log('Error: ', err);
                    });
            }
            // check if Treatment cycle data is dirty
            if (selectedClinic != '' && isTreatmentCycleDataDirty()) {
                let _data = {
                    clinicId: selectedClinic._id,
                    treatmentData: dirtyTreatmentCycles,
                };
                const token = "Bearer " + localStorage.getItem("accessToken")
                setInProgressCalls(inProgressCalls + 1);
                axios.post(
                    ENVIRONMENT_VARIABLES.Base_API_URL + "/TreatmentCycle/TreatmentCycle",
                    _data,
                    {
                        headers: { Authorization: token }
                    }
                )
                    .then(result => {
                        toast.success( 'Treatment cycle data updated successfully.' , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setTreatmentCycles(result.data);
                        setDirtyTreatmentCycles(result.data);
                        setInProgressCalls(inProgressCalls - 1);
                    })
                    .catch(err => {
                        toast.error( err.response.data.user_msg , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        console.log('Error: ', err);
                        setInProgressCalls(inProgressCalls - 1);
                    });
            }
            // check if Treatment revenue per hour data is dirty
            if (selectedClinic != '' && isTreatmentRevenuePerHourDataDirty()) {
                let _data = {
                    clinicId: selectedClinic._id,
                    treatmentData: dirtyTreatmentRevenuePerHour,
                };
                const token = "Bearer " + localStorage.getItem("accessToken")
                setInProgressCalls(inProgressCalls + 1);
                axios.post(
                    ENVIRONMENT_VARIABLES.Base_API_URL + "/TreatmentRevenuePerHour/upsertTreatmentRevenuePerHour",
                    _data,
                    {
                        headers: { Authorization: token }
                    }
                )
                    .then(result => {
                        toast.success( 'Treatment revenue per hour data updated successfully.' , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        setTreatmentRevenuePerHour(result.data);
                        setDirtyTreatmentRevenuePerHour(result.data);
                        setInProgressCalls(inProgressCalls - 1);
                    })
                    .catch(err => {
                        toast.error( err.response.data.user_msg , {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined,
                        });
                        console.log('Error: ', err);
                        setInProgressCalls(inProgressCalls - 1);
                    });
            }
        }
    }

    function handleSubmit(event) {
        // commenting out old form submit
        // event.preventDefault();
        // delete selectedClinic['clinicId'];
        // delete selectedClinic['createdAt'];
        // delete selectedClinic['updatedAt'];
        // selectedClinic.dailyOperatingHours = 'test';
        // props.actions.adminAction.updateKpiClinic({ ...selectedClinic, isSync: true });
    }

    return (
        <>
        <ToastContainer />
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item active>Clinic Configuration</Breadcrumb.Item>
                </Breadcrumb>
                <h4>Clinic Configuration</h4>
            </div>
        </div>

        <Card border="0" className="shadow p-3 pb-4 mb-4">
            <Card.Body className="p-0 p-md-4">
                <Form className="mt-4 clinic-hours-data" onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Form.Group id="userFullName" >
                            <Form.Label id="Clinic">Clinic:</Form.Label>
                            <Form.Select
                                defaultValue={kpiClinic}
                                onChange={(e) => onChangeClinicValue(e.target.value)}
                                className="kpiClinic-dd" id="kpiClinic">

                                <option defaultChecked>Choose...</option>
                                { clinic.map((row, index) => {
                                    return (
                                        <>
                                        <option
                                            key={index}
                                            value={row._id}
                                            >{row.clinicId}</option>
                                        </>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        <div className="config-item-wrapper">
                            <label className="form-label">Extracting Data</label>
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar extracting-data extracting-data-1">
                                    <thead>
                                        <tr>
                                            <td>
                                                Name
                                            </td>
                                            <td>
                                                Table Field
                                            </td>
                                            <td>
                                                Data Type
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div className="extracting-data-sidebar">
                                            <div style={{width: '61px', borderRight: '1px solid #B9B9B9'}}>
                                                Contact
                                            </div>
                                            <div style={{width: '209px', borderRight: '1px solid #B9B9B9'}}>
                                                Patient
                                            </div>
                                            <div style={{width: '338px', borderRight: '1px solid #B9B9B9'}}>
                                                Invoice
                                            </div>
                                            <div style={{width: '34px', borderRight: '1px solid #B9B9B9', borderTop: '1px solid #B9B9B9'}}></div>
                                        </div>
                                        <tr>
                                            <td>Branch</td>
                                            <td>invoice.branch</td>
                                            <td>VARCHAR(64)</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>invoice.date</td>
                                            <td>DATE</td>
                                        </tr>
                                        <tr>
                                            <td>Invoice No</td>
                                            <td>invoice.invoice_no</td>
                                            <td>VARCHAR(32)</td>
                                        </tr>
                                        <tr>
                                            <td>Item Sold</td>
                                            <td>invoice.item.name</td>
                                            <td>JSON</td>
                                        </tr>
                                        <tr>
                                            <td>Treatment Category</td>
                                            <td>invoice.item.category</td>
                                            <td>JSON</td>
                                        </tr>
                                        <tr>
                                            <td>Dr</td>
                                            <td>invoice.doctor</td>
                                            <td>VARCHAR(7)</td>
                                        </tr>
                                        <tr>
                                            <td>Amount</td>
                                            <td>invoice.sub_total</td>
                                            <td>DOUBLE</td>
                                        </tr>
                                        <tr>
                                            <td>Total Invoice Amount</td>
                                            <td>invoice.total</td>
                                            <td>DOUBLE</td>
                                        </tr>
                                        <tr>
                                            <td>Patient Payable</td>
                                            <td>invoice.payment.amount</td>
                                            <td>JSON</td>
                                        </tr>
                                        <tr>
                                            <td>Co-Payer</td>
                                            <td>invoice.corporate.corp</td>
                                            <td>JSON</td>
                                        </tr>
                                        <tr>
                                            <td>Co-Payer Payable</td>
                                            <td>invoice.corporate.amount</td>
                                            <td>JSON</td>
                                        </tr>
                                        <tr>
                                            <td>Time In</td>
                                            <td>invoice.created_on</td>
                                            <td>DATETIME</td>
                                        </tr>
                                        <tr>
                                            <td>Time Out</td>
                                            <td>invoice.finalized_on</td>
                                            <td>DATETIME</td>
                                        </tr>
                                        <tr>
                                            <td>Patient Ref No</td>
                                            <td>patient.given_id</td>
                                            <td>VARCHAR(32)</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td>patient.sex</td>
                                            <td>VARCHAR(6)</td>
                                        </tr>
                                        <tr>
                                            <td>Mode</td>
                                            <td>patient.mode</td>
                                            <td>VARCHAR(50)</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Birth</td>
                                            <td>patient.dob</td>
                                            <td>DATE</td>
                                        </tr>
                                        <tr>
                                            <td>Nationality</td>
                                            <td>patient.nationality</td>
                                            <td>VARCHAR(30)</td>
                                        </tr>
                                        <tr>
                                            <td>Occupation</td>
                                            <td>patient.occupation</td>
                                            <td>VARCHAR(30)</td>
                                        </tr>
                                        <tr>
                                            <td>Marital Status</td>
                                            <td>patient.marital_status</td>
                                            <td>VARCHAR(30)</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>patient.address</td>
                                            <td>VARCHAR(6)</td>
                                        </tr>
                                        <tr>
                                            <td>Referral Source</td>
                                            <td>contact.name</td>
                                            <td>VARCHAR(15)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="config-item-wrapper">
                            <label className="form-label">Daily Operating Hours</label>
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td style={{height: '1px', padding: '0px', borderTop: '0px', backgroundColor: 'transparent'}}></td>
                                            <td style={{height: '1px', padding: '0px', borderTop: '0px', backgroundColor: 'transparent'}}></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{backgroundColor:'#D9D9D9' ,color:'#000000', fontWeight:'700'}}>Branch Location</td>
                                            <td>{selectedClinic && selectedClinic.clinicId}</td>
                                        </tr>
                                        <tr>
                                            <td style={{backgroundColor:'#D9D9D9' ,color:'#000000', fontWeight:'700'}}>Effective Date</td>
                                            <td style={{paddingRight: '13px'}}>
                                                <Form.Group className="">
                                                    <Datetime
                                                        timeFormat={false}
                                                        closeOnSelect={true}
                                                        onChange={setEffectiveDate}
                                                        renderInput={(props, openCalendar) => (
                                                        <InputGroup>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            value={effectiveDate ? moment(effectiveDate).format("MM/DD/YYYY") : ""}
                                                            placeholder="mm/dd/yyyy"
                                                            style={{
                                                                border: '0',
                                                                borderBottom: '0.0625rem solid #D1D5DB',
                                                                borderRadius: '0px',
                                                                paddingLeft: '1px',
                                                                boxShadow: 'none'
                                                            }}
                                                            onFocus={openCalendar}
                                                            onChange={() => { }} />
                                                        </InputGroup>
                                                    )} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {<div className="config-item-wrapper">
                            <div className="table-with-sidebar-wrapper">
                                <div className="table-with-sidebar-sp-header"
                                    style={{width: '700px'}}>
                                    <div style={{width: '100px'}}></div>
                                    <div style={{width: '299.5px', paddingLeft: '30px'}}>Effective Operating Hours</div>
                                    <div style={{width: '300px', paddingLeft: '30px', borderLeft: '1px solid #B9B9B9'}}>Lunch/Break Hours</div>
                                </div>
                                <table className="table-with-sidebar operating-hours-days">
                                    <thead>
                                        <tr>
                                            <td style={{width: '100px'}}>
                                                
                                            </td>
                                            <td>
                                                Opening Hours
                                            </td>
                                            <td>
                                                Closing Hours
                                            </td>
                                            <td>
                                                Start
                                            </td>
                                            <td>
                                                End
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <div className="extracting-data-sidebar"
                                            style={{left: '-132px', bottom: '107px'}}>
                                            <div style={{width: '240px', borderRight: '1px solid #B9B9B9'}}>
                                                Day
                                            </div>
                                        </div>
                                        {
                                            dirtyOperatingHours.map((opObj, ind) => {
                                                return <tr key={`daily-op-hours-row-${ind}`}>
                                                    <td>{opObj.day}</td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['openingHours']}
                                                                onChange={(event) => changeOpHours(ind, 'openingHours', event.target.value)}
                                                                min={0} max={23}
                                                                placeholder="0" /> :  
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['openingMinutes']}
                                                                onChange={(event) => changeOpHours(ind, 'openingMinutes', event.target.value)}
                                                                min={0} max={59}
                                                                placeholder="0" /> HRS
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['closingHours']}
                                                                onChange={(event) => changeOpHours(ind, 'closingHours', event.target.value)}
                                                                min={0} max={23}
                                                                placeholder="0" /> : 
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['closingMinutes']}
                                                                onChange={(event) => changeOpHours(ind, 'closingMinutes', event.target.value)}
                                                                min={0} max={59}
                                                                placeholder="0" /> HRS
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['bStartHours']}
                                                                onChange={(event) => changeOpHours(ind, 'bStartHours', event.target.value)}
                                                                min={0} max={23}
                                                                placeholder="0" /> : 
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['bStartMinutes']}
                                                                onChange={(event) => changeOpHours(ind, 'bStartMinutes', event.target.value)}
                                                                min={0} max={59}
                                                                placeholder="0" /> HRS
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['bEndHours']}
                                                                onChange={(event) => changeOpHours(ind, 'bEndHours', event.target.value)}
                                                                min={0} max={23}
                                                                placeholder="0" /> : 
                                                            <Form.Control type="number"
                                                                className="op-hr-time-field"
                                                                value={opObj['bEndMinutes']}
                                                                onChange={(event) => changeOpHours(ind, 'bEndMinutes', event.target.value)}
                                                                min={0} max={59}
                                                                placeholder="0" /> HRS
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {/* #286: JSX for all 5 costs */}
                        {/* material */}
                        <div className="config-item-wrapper">
                            {/* adjust this */}
                            <label className="form-label">Material Cost</label> 
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Item Name
                                            </td>
                                            {/* adjust this */}
                                            <td>
                                                Material Cost ($/unit)
                                            </td>
                                            <td>
                                                Effective Date
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* adjust this */}
                                        {dirtyMaterialCostItems.map((item, ind) => {
                                            // adjust this
                                            return <tr key={`material-cost-${ind}`}>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="text"
                                                            required={true}
                                                            className="repeater-input-field"
                                                            // adjust this
                                                            value={item.materialName}
                                                            // adjust this
                                                            onChange={(event) => changeMaterialItem(ind, 'materialName', event.target.value)}
                                                            // adjust this
                                                            placeholder="Name" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="number"
                                                            className="repeater-input-field"
                                                            step={0.01}
                                                            // adjust this
                                                            value={item.materialCost}
                                                            // adjust this
                                                            onChange={(event) => changeMaterialItem(ind, 'materialCost', event.target.value)}
                                                            // adjust this
                                                            placeholder="Cost" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Datetime
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                // change this
                                                                onChange={(newVal) => changeMaterialItem(ind, 'effectiveDate', newVal)}
                                                                renderInput={(props, openCalendar) => (
                                                                <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={item.effectiveDate ? moment(item.effectiveDate).format("MM/DD/YYYY") : ""}
                                                                    placeholder="mm/dd/yyyy"
                                                                    style={{
                                                                        border: '0',
                                                                        boxShadow: 'none'
                                                                    }}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                </InputGroup>
                                                            )} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                        })}
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                onClick={() => addNewMaterialItem()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* misc */}
                        <div className="config-item-wrapper">
                            {/* adjust this */}
                            <label className="form-label">Miscellaneous Cost</label> 
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Item Name
                                            </td>
                                            {/* adjust this */}
                                            <td>
                                                Miscellaneous Cost ($/unit)
                                            </td>
                                            <td>
                                                Effective Date
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* adjust this */}
                                        {dirtyMiscCostItems.map((item, ind) => {
                                            // adjust this
                                            return <tr key={`misc-cost-${ind}`}>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="text"
                                                            required={true}
                                                            className="repeater-input-field"
                                                            // adjust this
                                                            value={item.miscName}
                                                            // adjust this
                                                            onChange={(event) => changeMiscItem(ind, 'miscName', event.target.value)}
                                                            // adjust this
                                                            placeholder="Name" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="number"
                                                            className="repeater-input-field"
                                                            step={0.01}
                                                            // adjust this
                                                            value={item.miscCost}
                                                            // adjust this
                                                            onChange={(event) => changeMiscItem(ind, 'miscCost', event.target.value)}
                                                            // adjust this
                                                            placeholder="Cost" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Datetime
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                // change this
                                                                onChange={(newVal) => changeMiscItem(ind, 'effectiveDate', newVal)}
                                                                renderInput={(props, openCalendar) => (
                                                                <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={item.effectiveDate ? moment(item.effectiveDate).format("MM/DD/YYYY") : ""}
                                                                    placeholder="mm/dd/yyyy"
                                                                    style={{
                                                                        border: '0',
                                                                        boxShadow: 'none'
                                                                    }}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                </InputGroup>
                                                            )} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                        })}
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                // adjust this
                                                onClick={() => addNewMiscItem()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* overhead */}
                        <div className="config-item-wrapper">
                            {/* adjust this */}
                            <label className="form-label">Overhead Cost</label> 
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Item Name
                                            </td>
                                            {/* adjust this */}
                                            <td>
                                                Overhead Cost ($/unit)
                                            </td>
                                            <td>
                                                Effective Date
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* adjust this */}
                                        {dirtyOverheadCostItems.map((item, ind) => {
                                            // adjust this
                                            return <tr key={`overhead-cost-${ind}`}>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="text"
                                                            required={true}
                                                            className="repeater-input-field"
                                                            // adjust this
                                                            value={item.overheadName}
                                                            // adjust this
                                                            onChange={(event) => changeOverheadItem(ind, 'overheadName', event.target.value)}
                                                            // adjust this
                                                            placeholder="Name" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="number"
                                                            className="repeater-input-field"
                                                            step={0.01}
                                                            // adjust this
                                                            value={item.overheadCost}
                                                            // adjust this
                                                            onChange={(event) => changeOverheadItem(ind, 'overheadCost', event.target.value)}
                                                            // adjust this
                                                            placeholder="Cost" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Datetime
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                // change this
                                                                onChange={(newVal) => changeOverheadItem(ind, 'effectiveDate', newVal)}
                                                                renderInput={(props, openCalendar) => (
                                                                <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={item.effectiveDate ? moment(item.effectiveDate).format("MM/DD/YYYY") : ""}
                                                                    placeholder="mm/dd/yyyy"
                                                                    style={{
                                                                        border: '0',
                                                                        boxShadow: 'none'
                                                                    }}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                </InputGroup>
                                                            )} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                        })}
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                // adjust this
                                                onClick={() => addNewOverheadItem()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* dentist commission */}
                        <div className="config-item-wrapper">
                            {/* adjust this */}
                            <label className="form-label">Dentist Commission</label> 
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Item Name
                                            </td>
                                            {/* adjust this */}
                                            <td>
                                                Dentist Commission ($/unit)
                                            </td>
                                            <td>
                                                Effective Date
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* adjust this */}
                                        {dirtyDentistCommissionItems.map((item, ind) => {
                                            // adjust this
                                            return <tr key={`dentist-commission-${ind}`}>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="text"
                                                            required={true}
                                                            className="repeater-input-field"
                                                            // adjust this
                                                            value={item.dentistCommissionName}
                                                            // adjust this
                                                            onChange={(event) => changeDentistCommissionItem(ind, 'dentistCommissionName', event.target.value)}
                                                            // adjust this
                                                            placeholder="Name" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="number"
                                                            className="repeater-input-field"
                                                            step={0.01}
                                                            // adjust this
                                                            value={item.dentistCommission}
                                                            // adjust this
                                                            onChange={(event) => changeDentistCommissionItem(ind, 'dentistCommission', event.target.value)}
                                                            // adjust this
                                                            placeholder="Commission" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Datetime
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                // change this
                                                                onChange={(newVal) => changeDentistCommissionItem(ind, 'effectiveDate', newVal)}
                                                                renderInput={(props, openCalendar) => (
                                                                <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={item.effectiveDate ? moment(item.effectiveDate).format("MM/DD/YYYY") : ""}
                                                                    placeholder="mm/dd/yyyy"
                                                                    style={{
                                                                        border: '0',
                                                                        boxShadow: 'none'
                                                                    }}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                </InputGroup>
                                                            )} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                        })}
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                // adjust this
                                                onClick={() => addNewDentistCommissionItem()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* payment mode discount */}
                        <div className="config-item-wrapper">
                            {/* adjust this */}
                            <label className="form-label">Payment Mode Discount</label> 
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Payment Mode
                                            </td>
                                            {/* adjust this */}
                                            <td>
                                                Discount ($/unit)
                                            </td>
                                            <td>
                                                Effective Date
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* adjust this */}
                                        {dirtyPaymentModeItems.map((item, ind) => {
                                            // adjust this
                                            return <tr key={`payment-discount-${ind}`}>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="text"
                                                            required={true}
                                                            className="repeater-input-field"
                                                            // adjust this
                                                            value={item.paymentModeName}
                                                            // adjust this
                                                            onChange={(event) => changePaymentModeItem(ind, 'paymentModeName', event.target.value)}
                                                            // adjust this
                                                            placeholder="CASH/VISA" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Form.Control type="number"
                                                            className="repeater-input-field"
                                                            step={0.01}
                                                            // adjust this
                                                            value={item.paymentModeDiscount}
                                                            // adjust this
                                                            onChange={(event) => changePaymentModeItem(ind, 'paymentModeDiscount', event.target.value)}
                                                            // adjust this
                                                            placeholder="Discount" />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group>
                                                        <Datetime
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                // change this
                                                                onChange={(newVal) => changePaymentModeItem(ind, 'effectiveDate', newVal)}
                                                                renderInput={(props, openCalendar) => (
                                                                <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={item.effectiveDate ? moment(item.effectiveDate).format("MM/DD/YYYY") : ""}
                                                                    placeholder="mm/dd/yyyy"
                                                                    style={{
                                                                        border: '0',
                                                                        boxShadow: 'none'
                                                                    }}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                </InputGroup>
                                                            )} />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                        })}
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                // adjust this
                                                onClick={() => addNewPaymentModeItem()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="config-item-wrapper">
                            <label className="form-label">Number of Chairs</label>
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Effective Date
                                            </td>
                                            <td>
                                                Branch Location
                                            </td>
                                            <td>
                                                Number of Chairs
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dirtyNumberOfChairs.map((item, ind) => {
                                                return <tr key={`number-of-chairs-${ind}`}>
                                                    <td>
                                                        <Form.Group className="">
                                                            <Datetime
                                                                timeFormat={false}
                                                                closeOnSelect={true}
                                                                onChange={(newVal) => changeNumberOfChairs(ind, 'effectiveDate', newVal)}
                                                                renderInput={(props, openCalendar) => (
                                                                <InputGroup>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={item.effectiveDate ? moment(item.effectiveDate).format("MM/DD/YYYY") : ""}
                                                                    placeholder="mm/dd/yyyy"
                                                                    style={{
                                                                        border: '0',
                                                                        boxShadow: 'none'
                                                                    }}
                                                                    onFocus={openCalendar}
                                                                    onChange={() => { }} />
                                                                </InputGroup>
                                                            )} />
                                                        </Form.Group>
                                                    </td>
                                                    <td>{selectedClinic.clinicId}</td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="repeater-input-field"
                                                                step={'any'}
                                                                value={item.chairs}
                                                                onChange={(event) => changeNumberOfChairs(ind, 'chairs', event.target.value)} />
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                onClick={() => addNewChairsEntry()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="config-item-wrapper">
                            <label className="form-label">Treatment Cycle</label>
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Treatment
                                            </td>
                                            <td>
                                                Average Visits per Treatment Cycle
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dirtyTreatmentCycles.map((cycle, ind) => {
                                                return (<tr key={`treatment-cycle-${ind}`}>
                                                    <td style={{paddingLeft: '1px'}}>
                                                        <Form.Group>
                                                            <Form.Select
                                                                defaultValue={cycle.name}
                                                                style={{
                                                                    borderRadius: '1px',
                                                                    border: '1px solid #B9B9B9'
                                                                }}
                                                                onChange={(event) => changeTreatmentCycle(cycle, ind, 'name', event.target.value)}
                                                            >
                                                                <option>Choose...</option>
                                                                {allTreatments.map((treatment, ind2) => {
                                                                    let isChecked = cycle.name == treatment.name;
                                                                    return (
                                                                       <option
                                                                            key={`all-treatments-${ind2}`}
                                                                            value={treatment.name}
                                                                            selected={isChecked}
                                                                        >
                                                                           {treatment.name}
                                                                        </option> 
                                                                    )
                                                                })}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="repeater-input-field"
                                                                step={'any'}
                                                                value={cycle.avgVisits}
                                                                min={1}
                                                                onChange={(event) => changeTreatmentCycle(cycle, ind, 'avgVisits', event.target.value)}
                                                                placeholder="" />
                                                        </Form.Group>
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                onClick={() => addNewTreatmentEntry()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="config-item-wrapper">
                            <label className="form-label">KPI Planner: Default Revenue/Hr</label>
                            <div className="table-with-sidebar-wrapper">
                                <table className="table-with-sidebar operating-hours">
                                    <thead>
                                        <tr>
                                            <td>
                                                Treatment
                                            </td>
                                            <td>
                                                Revenue/Hr
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dirtyTreatmentRevenuePerHour.map((treatmentData, ind) => {
                                                return (<tr key={`treatment-revenue-per-hour-${ind}`}>
                                                    <td style={{paddingLeft: '1px'}}>
                                                        <Form.Group>
                                                            <Form.Select
                                                                defaultValue={treatmentData.name}
                                                                style={{
                                                                    borderRadius: '1px',
                                                                    border: '1px solid #B9B9B9'
                                                                }}
                                                                onChange={(event) => changeTreatmentRevenuePerHour(treatmentData, ind, 'treatment', event.target.value)}
                                                            >
                                                                <option>Choose...</option>
                                                                {allTreatments.map((treatment, ind2) => {
                                                                    let isChecked = treatmentData.treatment == treatment.name;
                                                                    return (
                                                                       <option
                                                                            key={`all-treatments-${ind2}`}
                                                                            value={treatment.name}
                                                                            selected={isChecked}
                                                                        >
                                                                           {treatment.name}
                                                                        </option> 
                                                                    )
                                                                })}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group>
                                                            <Form.Control type="number"
                                                                className="repeater-input-field"
                                                                step={'any'}
                                                                value={treatmentData.revenuePerHour}
                                                                onChange={(event) => changeTreatmentRevenuePerHour(treatmentData, ind, 'revenuePerHour', event.target.value)}
                                                                placeholder="" />
                                                        </Form.Group>
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                        <div>
                                            <Button
                                                variant="gray-700"
                                                style={{
                                                    position: 'absolute',
                                                    marginTop: '5px',
                                                    right: 0
                                                }}
                                                onClick={() => addNewTreatmentRevenuePerHourEntry()}
                                            >
                                                +
                                            </Button>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        {/* #271: Add button to auto-generate average duration */}
                        {/* #271: Add info about date range and default value */}
                        <div className="config-item-wrapper">
                            <label className="form-label">Average Duration</label> 
                            <div className="average-duration">
                                <div>
                                    Automatically refresh average duration value for each treatment based on past invoice data for the last {configAvgDurationDateRangeMonth} months with default treatment average duration of {configAvgDurationDefault}.
                                </div>
                                <div>
                                    <Button
                                        variant="gray-700"
                                        className={`${inProgressCalls > 0? 'disabled': ''}`}
                                        style={{
                                            position: 'absolute',
                                            marginTop: '5px',
                                            right: 0
                                        }}
                                        onClick={() => generateAvgDuration()}
                                    >
                                        Refresh Average Duration of Treatment
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="config-item-wrapper">
                            <Button variant="gray-800"
                                className={`clinic-config-save ${inProgressCalls > 0? 'disabled': ''}`}
                                size="lg" onClick={saveClinicConfig}
                            > Save </Button>
                        </div>
                    </Row>
                </Form>
            </Card.Body>
        </Card>


        { adminAuthReducer.isLoading ? <Loader/> : ''}  
        </>
    )

}

const mapDispatchToProps = dispatch => ({
    actions: {
        adminAction: bindActionCreators(authAction, dispatch)
    }
});

export default connect(null, mapDispatchToProps)(ClinicConfiguration)