import { Col, Row, Form, Button, Container, Alert, Breadcrumb, Card } from 'react-bootstrap';
import { HomeIcon } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from 'assets/img/brand/Medtrik-Logo.png';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Loading from '../Users/loading.component';

function ImageComponent() {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch the image URL from the backend
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/Oauths/check`,
    })
      .then((response) => {
        if (!response.data.data) {
          axios({
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/Oauths/qrimage`,
          })
            .then((response) => {
              setImageUrl(response.data.data);
            })
            .catch((error) => {
              console.error('Error fetching image:', error);
            });
        }
      })
      .catch((error) => {
        // console.error('Error fetching twoFactor:', error);
        axios({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/Oauths/qrimage`,
        })
          .then((response) => {
            setImageUrl(response.data.data);
          })
          .catch((error) => {
            console.error('Error fetching image:', error);
          });
      });
  }, []);

  return <img id="qrImage" src={imageUrl} height="150" width="150" alt="QR Code" />;
}

export default function TwoFactorLogin() {
  const [twoFactor, setTwoFactor] = useState();
  const [alert, setAlert] = useState(false);
  const [isLoading, setLoading] = useState();
  const [secretCode, setSecretCode] = useState('');
  const history = useHistory();
  const [error, setError] = useState(false);

  const handleCloseError = () => {
    setError(false);
  };

  useEffect(() => {
    window.onpopstate = () => {
      localStorage.removeItem('accessToken');
    };
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      history.push('/');
    }
  });

  useEffect(() => {
    setLoading(true);
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/Oauths/check`,
    })
      .then((response) => {
        setTwoFactor(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching twoFactor:', error);
        setLoading(false);
      });
  }, [twoFactor]);

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const insertTwoFactor = () => {
    setLoading(true);
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/Oauths/twofactor`,
      data: {
        secretKey: secretCode,
      },
    })
      .then((response) => {
        setLoading(false);
        setAlert(true);
        setSecretCode('');
        setTwoFactor(true);
        setError(false);
      })
      .catch((error) => {
        // console.error('Error fetching twoFactor:', error);
        setLoading(false);
        setAlert(false);
        setError(true);
        setSecretCode('');
      });
  };

  // const insertTwoFactor = () => {
  //   // setLoading(true);
  //   axios({
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  //     },
  //     method: 'POST',
  //     url: `${process.env.REACT_APP_API_URL}/Oauths/login_2fa`,
  //     data: {
  //       secretKey: secretCode,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.data.data) {
  //         history.push('/dashboard');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching twoFactor:', error);
  //       setError(true);
  //     });
  // };
  // return (
  //   <>
  //     <main>
  //       <ToastContainer />
  //       {error && (
  //         <Alert variant="danger" onClose={handleCloseError} dismissible>
  //           Failed to verification. Please Check Your Code.
  //         </Alert>
  //       )}
  //       <section className="d-flex vh-100 bg-white">
  //         <Container className="mt-6">
  //           <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${Logo})` }}>
  //             <Col xs={12} className="d-flex align-items-center justify-content-center">
  //               <div className={`bg-white border-0 rounded border-light p-4 w-100 fmxw-500`}>
  //                 <div className="text-center text-md-center mb-4 mt-md-0">
  //                   <img src={Logo} alt="Medtrik Logo" />
  //                 </div>

  //                 <Form className="mt-3">
  //                   <Form.Group id="password" className="d-flex align-items-center mb-3">
  //                     <Col xs={4}>
  //                       <Form.Label className="me-2">Secret Code:</Form.Label>
  //                     </Col>
  //                     <Col xs={8}>
  //                       <Form.Control
  //                         required
  //                         value={secretCode}
  //                         onChange={(event) => {
  //                           setSecretCode(event.target.value);
  //                         }}
  //                         type="text"
  //                       />
  //                     </Col>
  //                   </Form.Group>
  //                   <Button
  //                     variant="gray-800"
  //                     onClick={insertTwoFactor}
  //                     className="login-button px-5"
  //                     disabled={secretCode == ''}
  //                     type="button"
  //                   >
  //                     Verification
  //                   </Button>
  //                 </Form>
  //               </div>
  //             </Col>
  //           </Row>
  //         </Container>
  //       </section>
  //     </main>
  //   </>
  // );
  return isLoading === true ? (
    <>
      <Card border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Loading />
          </div>
        </Card.Body>
      </Card>
    </>
  ) : (
    <>
      {alert && (
        <Alert variant="success" onClose={handleCloseAlert} dismissible>
          2FA setup successful
        </Alert>
      )}
      {error && (
        <Alert variant="danger" onClose={handleCloseError} dismissible>
          Failed to set up 2FA. Please remove from Google Authenticator & rescan the QR code.
        </Alert>
      )}
      <Card border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          {twoFactor === false ? (
            <Form className="mt-4">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div id="twoFAFormHolder" className="d-flex flex-column align-items-center gap-3">
                  <h2>How To Setup 2FA</h2>
                  <p>
                    1. Download Google Authenticator (
                    <a
                      target="__blank"
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                    >
                      Here
                    </a>
                    )
                  </p>
                  <p>2. Open Google Authenticator And Scan Barcode Below</p>
                  <p>3. Enter Secret Code/OTP in Text Box Below & Hit The Set Button</p>
                  <ImageComponent />
                  <form id="twoFAUpdateForm" className="d-flex flex-column gap-2">
                    <input
                      type="text"
                      name="code"
                      value={secretCode}
                      onChange={(event) => {
                        setSecretCode(event.target.value);
                      }}
                      placeholder="2FA Code"
                      className="form-control"
                    />
                    <button onClick={insertTwoFactor} className="btn btn-primary" type="button">
                      SET
                    </button>
                  </form>
                </div>
              </div>
            </Form>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center gap-3">
              <h1>2FA Already Enabled</h1>

              <button
                onClick={() => {
                  localStorage.removeItem('accessToken');
                  history.push('/');
                }}
                className="btn btn-primary mt-2"
                type="button"
              >
                Back To Login
              </button>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
