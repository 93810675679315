import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import ReactApexChart from 'react-apexcharts';
import './index.css';
import { formatAmount } from 'utils/parser';

const useStyles = makeStyles(() => ({
  fontF: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    color: '#2f4858',
  },
}));

const TotalRevenueBarChart = ({ isEmbedded, revenueBarGraph }) => {
  const classes = useStyles();
  const [totalRevenueBargraph, setTotalRevenueBargraph] = useState('');
  const [seriesData, setSeriesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [radioValue, setRadioValue] = useState('1');            // #381: RADIO OPTION STATE 
  const [sortByDoctor, setSortByDoctor] = useState(false);      // #381: SORT BY DOCTOR (true) OR SORT BY REVENUE (false) STATE

  // #381: RADIO OPTIONS
  const radios = [
    { name: 'By Revenue', value: '2' },
    { name: 'By Doctor', value: '3' },
  ];

  // #268: CENTERED DATA LABELS AND MAKE SURE IT'S ON TOP
  const state = {
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 380,
        width: '100%',
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          vertical: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors: ['#CCC05A', '#8672A5', '#546E7A', '#d4526e'],
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          colors: ['#2f4858'],
          fontSize: '10px',
          padding: '0 30px',
        },
        formatter: (value) => formatAmount(value),
        offsetX: 0,
        offsetY: -25,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#ddd'],
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: 10,
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
        },
      },
      title: {
        text: `Total Revenue  ${formatAmount(totalRevenueBargraph)}`,
        align: 'center',
        floating: true,
        offsetY: -6,
        style: {
          fontFamily: 'Poppins',
          fontWeight: '500',
          fontSize: '18px',
        },
      },
      tooltip: {
        style: {
          textAlign: 'center',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div>' +
            '<span>' +
            w.globals.labels[dataPointIndex] +
            ' : ' +
            formatAmount(series[seriesIndex][dataPointIndex]) +
            '</span>' +
            '</div>'
          );
        },
      },
    },
  };

  const stateEmbed = {
    series: [
      {
        data: seriesData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 380,
        width: '100%',
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          vertical: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors: ['#CCC05A', '#8672A5', '#546E7A', '#d4526e'],
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
        textAnchor: 'middle',
        style: {
          colors: ['#2f4858'],
          fontSize: '10x',
          padding: '0 30px',
        },
        formatter: (value) => formatAmount(value),
        offsetX: 0,
        offsetY: -25,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#ddd'],
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: 10,
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        labels: {
          formatter: (value) =>
            value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
        },
      },
      title: {
        text: `Total Revenue  ${formatAmount(totalRevenueBargraph)}`,
        align: 'center',
        floating: true,
        offsetY: -6,
        style: {
          fontFamily: 'Poppins',
          fontWeight: '500',
          fontSize: '18px',
        },
      },
      tooltip: {
        style: {
          textAlign: 'center',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div>' +
            '<span>' +
            w.globals.labels[dataPointIndex] +
            ' : ' +
            formatAmount(series[seriesIndex][dataPointIndex]) +
            '</span>' +
            '</div>'
          );
        },
      },
    },
  };

  useEffect(() => {
    if (!isEmbedded) {
      window.history.replaceState(null, 'Medtrik', '/TotalRevenueBarChart');
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    //set series data
    let data = [];
    let categories = [];
    
    if (revenueBarGraph) {
      // #429: only show non-zero bars
      let filtered = revenueBarGraph.filter((rbg) => rbg.totalTreatmentRevenue && rbg.totalTreatmentRevenue!=0)
      const sortedRevenueBarGraph = [...filtered].sort((a, b) => {
        if (sortByDoctor) {
          // #381: Sort alphabetically by doctor name
          return a.doctorName.localeCompare(b.doctorName);
        } else {
          // #381: Sort by total treatment revenue
          return parseFloat(b.totalTreatmentRevenue) - parseFloat(a.totalTreatmentRevenue);
        }
      });

    // #381: MAKE SURE 'Others' COLUMN IS LAST 
    const othersKey = _.findIndex(sortedRevenueBarGraph, ['doctorName','Others']);
    if (othersKey !== -1) {
      sortedRevenueBarGraph.push(sortedRevenueBarGraph[othersKey]);
      sortedRevenueBarGraph.splice(othersKey, 1);
    }

      sortedRevenueBarGraph.map((item) => {
        categories.push(item.doctorName);
        data.push(item.totalTreatmentRevenue);
      });
    }
    setTotalRevenueBargraph(_.sum(data));
    setSeriesData(data);
    setCategories(categories);
  }, [revenueBarGraph, sortByDoctor]); // #381: ADD sortByDoctor TRIGGER

  // #381: handle button sort
  const handleSortByDoctorClick = (value) => {
    if (value === '3') {
      setSortByDoctor((prevSortByDoctor) => !prevSortByDoctor);
    } else if (value === '2') {
      setSortByDoctor(false);
    }
  };

  return (
    <>
      {isEmbedded === false ? (
        // #381: ADD RADIO BUTTONS ON TOP OF GRAPH DISPLAY IF NOT EMBEDDED
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <ButtonGroup style={{ marginBottom: '30px' }}>
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={'outline-success'}
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onChange={(e) => setRadioValue(e.currentTarget.value)}
                    onClick={() => handleSortByDoctorClick(radio.value)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
          </div>
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={450}
            width={'100%'}
            className={classes.fontF}
          />
        </>
      ) : (
        // #381: GRAPH DISPLAY WHEN EMBEDDED 
        <Row className="justify-content-left kpi-planner" style={{ marginBottom: '14px' }}>
          <Col xs={12} lg={isEmbedded ? 12 : 6} md={isEmbedded ? 12 : 6} className="mt-4">
            <ReactApexChart
              options={stateEmbed.options}
              series={stateEmbed.series}
              type="bar"
              height={450}
              width={'100%'}
              className={classes.fontF}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default TotalRevenueBarChart;
