import React, {
  useEffect,
  useState,
  useMemo,
} from "react"
import { Container, Col, Row, Card } from "react-bootstrap"

import { Routing } from "routes"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom"
import * as authAction from "actions/authActions"
import * as drPerformanceAction from "actions/drPerformanceActions"

import "./style.css"
import MTDPerformance from "./MTDPerformance"
import YearlySummary from "./YearlySummary"
import MTD from "./MTD"
import MonthlyPerformance from "./MonthlyPerformance"
import MonthlySummary from "./MonthlySummary"
import RollingPerformance from "./RollingPerformance"
import TreatmentMix from "./TreatmentMix"
import YTD from "./YTD"
import NotFound from "pages/examples/NotFound"
import _ from "underscore"
import useClinics from "hooks/useClinics"
import useLoginDetails from "hooks/useLoginDetails"
import useDoctors from "hooks/useDoctors"
import {MONTH_SLIDER} from "constants/date"
import Header from "components/Header"
import MultiSelect from "../filters/MultiSelect"
import MonthSlider from "components/filters/MonthSlider"
import YearSlider from "components/filters/YearSlider"
import { useChainId } from "hooks/useChainId"
import moment from "moment";
import { startOfMonth, sub } from "date-fns"; // #270: added to change lower boundary of monthly slider for Monthly Performance 

const PerformanceReport = () => {
  const routeMatch = useRouteMatch()

  return (
    <>
      <Switch>
        <Route exact path={`${routeMatch.path}`}>
          <Renderer />
        </Route>
        <Route
          path={`${routeMatch.path}${Routing.DrPerformanceReport.MonthlySummary.relativePath}`}
        >
          <Renderer selectedComponent="Monthly Summary" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.YearlySummary.relativePath}`}
        >
          <Renderer selectedComponent="Yearly Summary" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.MTDPerformance.relativePath}`}
        >
          <Renderer selectedComponent="MTD Performance" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.MTD.relativePath}`}
        >
          <Renderer selectedComponent="YTD Current Yr vs Previous Yr" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.MonthlyPerformance.relativePath}`}
        >
          <Renderer selectedComponent="Monthly Performance" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.RollingPerformance.relativePath}`}
        >
          <Renderer selectedComponent="Rolling Performance (Last 12 months)" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.TreatmentMix.relativePath}`}
        >
          <Renderer selectedComponent="Treatment Mix" />
        </Route>
        <Route
          exact
          path={`${routeMatch.path}${Routing.DrPerformanceReport.YTD.relativePath}`}
        >
          <Renderer selectedComponent="Tabulated Metrics Report (YTD by Month)" />
        </Route>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </>
  )
}

let Renderer = (props) => {
  const user = useLoginDetails()
  const [roleId, setRoleId] = useState(undefined)
  const [doctorId, setDoctorId] = useState(user.Role.id == 4 ? [{ value: user.doctor.doctorId, label: user.doctor.name}] : [{label: 'All Doctors', value: 'all'}])
  const [doctorName, setDoctorName] = useState(undefined)
  // #298: added new state to store selected clinic array
  const [clinicId, setClinicId] = useState(user.Role.id == 3 ? [{label: user.clinic.clinicId, value: user.clinic.clinicId}] : [{label: 'All Clinics', value: 'all'}]);
  
  const clinics = useClinics()
  const doctors = useDoctors()
  const chainId = useChainId()

  const [selectedDate, setSelectedDate] = useState({})
  const [selectedDateRange, setSelectedDateRange] = useState({})
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))
  const [selectedComponent, setSelectedComponent] = useState(props.selectedComponent)

  useEffect(() => {
    user?.Role?.id && setRoleId(user.Role.id)
  }, [user?.Role?.id])


  // happening: api requests
  useEffect(() => {
    if (!roleId) return

    //admin
    if (roleId === 1 || roleId === 5) return
    
    // #298: change getClinics() to getUserClinicsByRole()
    //chain manger || clinic manager || doctor
    if (roleId === 2 || roleId === 3 || roleId === 4) {
      !clinics?.length && props.actions.adminAction.getUserClinicsByRole(roleId, chainId, user);
      !doctors?.length && props.actions.adminAction.getDoctors();
    }
  }, [roleId])

  let doctorOptions = useMemo(() => {
    if (roleId === 2 || roleId === 3)
      return doctors?.map(({ doctorId: did, name }, ind) => {
                return {value: did, label: name}
              }) ?? []

    if (roleId === 4) return [{ value: user.doctor.doctorId, label: user.doctor.name}]
  }, [roleId, doctors, user?.doctor?.doctorId])

  // #298: get clinicOptions for filter
  let clinicOptions = useMemo(() => {
    return clinics?.map(({ clinicId: cid }, ind) => {
      return {value: cid, label: cid}
    }) ?? []
  }, [roleId, clinics])

  // #298: the parameter for each action should be adjusted to accommodate clinicId
  useEffect(() => {
    if(!_.isEmpty(doctorId) && !_.isEmpty(clinicId) && selectedDateRange.startDate && selectedDateRange.endDate) {
      props.actions?.drPerformanceAction?.getMTDPerformanceCurrentYear(chainId, doctorId?.map(e => e.value), selectedDateRange.startDate, selectedDateRange.endDate, clinicId?.map(e => e.value))
    }
  }, [chainId, doctorId, selectedDateRange, clinicId]);

  // #298: the parameter for each action should be adjusted to accommodate clinicId
  useEffect(() => {
    if(!_.isEmpty(doctorId) && !_.isEmpty(clinicId) && selectedDate.currentDate) {
      props.actions?.drPerformanceAction?.getMonthly(chainId, doctorId?.map(e => e.value), selectedDate.currentDate, clinicId?.map(e => e.value))
    }
  }, [chainId, doctorId, selectedDate, clinicId]);

  // #298: the parameter for each action should be adjusted to accommodate clinicId
  useEffect(() => {
    if(!_.isEmpty(doctorId) && !_.isEmpty(clinicId)) {
      props.actions?.drPerformanceAction?.getMTDPerformanceCurrVsPrev(chainId, doctorId?.map(e => e.value), clinicId?.map(e => e.value))
      props.actions?.drPerformanceAction?.getMTDPerformance(chainId, doctorId?.map(e => e.value), clinicId?.map(e => e.value))
      props.actions?.drPerformanceAction?.getRolling(chainId, doctorId?.map(e => e.value), moment().format('MM-DD-YYYY'), clinicId?.map(e => e.value))
    }
  }, [chainId, doctorId, clinicId]);

  // #298: the parameter for each action should be adjusted to accommodate clinicId
  useEffect(() => {
    if(!_.isEmpty(doctorId) && selectedYear && !_.isEmpty(clinicId)) {
      props.actions?.drPerformanceAction?.getTreatmentMix(chainId, doctorId?.map(e => e.value), selectedYear, clinicId?.map(e => e.value))
      props.actions?.drPerformanceAction?.getYearly(chainId, doctorId?.map(e => e.value), selectedYear, clinicId?.map(e => e.value))
      props.actions?.drPerformanceAction?.getYTD( chainId, doctorId?.map(e => e.value), selectedYear, clinicId?.map(e => e.value))
    }
  }, [chainId, doctorId, selectedYear, clinicId]);

  let handleDoctorChange = (change=[]) => {
    setDoctorId(change) 
    change?.length ?? setDoctorName(change.length === 1 ? (change.some(e => e.value === "all") ? "Doctor" : change[0].label) : "Doctors")
  }

  // #298: onChange function for clinic filter
  let handleClinicChange = (change=[]) => {
    setClinicId(change);
  }
  
  const handleMonthChange = (date) => {
    setSelectedDate(date)
  }

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };
  
  const handleYearChange = (year) => {
    setSelectedYear(year)
  }

  const componentMapping = {
    'Monthly Summary': {
      component: MonthlySummary,
      widthPercentage: 40,
      monthSlider: true,
      monthSliderType: 1,
      yearlySlider: false,
    },
    'MTD Performance': {
      component: MTDPerformance,
      widthPercentage: 30,
      monthSlider: false,
      yearlySlider: false,
    },
    'YTD Current Yr vs Previous Yr': {
      component: MTD,
      widthPercentage: 30,
      monthSlider: false,
      yearlySlider: false,
    },
    'Monthly Performance': {
      component: MonthlyPerformance,
      widthPercentage: 40,
      monthSlider: true,
      monthSliderType: 2,
      yearlySlider: false,
    },
    'Rolling Performance (Last 12 months)': {
      component: RollingPerformance,
      widthPercentage: 60,
      monthSlider: false,
      yearlySlider: false,
    },
    'Yearly Summary': {
      component: YearlySummary,
      widthPercentage: 40,
      monthSlider: false,
      yearlySlider: true,
    },
    'Tabulated Metrics Report (YTD by Month)': {
      component: YTD,
      widthPercentage: 60,
      monthSlider: false,
      yearlySlider: true,
    },
    'Treatment Mix': {
      component: TreatmentMix,
      widthPercentage: 100,
      monthSlider: false,
      yearlySlider: true,
    },
  }

  const getSelectedComponent = (componentName, isEmbedded = false) => {
    const SelectedComponent = componentMapping[componentName].component;
    return <SelectedComponent
      doctorId={doctorId?.map(e => e.value)}
      doctorName={doctorName}
      selectedYear={selectedYear}
      isEmbedded={isEmbedded}
      {...selectedDateRange}
      {...selectedDate}
      {...props}
    />;
  }

  return (
    <Container fluid={true}>
      <Header title={"Dr Performance Report"} setSelectedComponent={setSelectedComponent} selectedComponent={selectedComponent} />
      <Card border='0' className='table-wrapper table-responsive shadow w-100'>
        <Card.Body>
          <Row className="mb-4">
            <Col xs={12} lg={3}>
              <MultiSelect placeholder={"Select Doctor"} onChange={handleDoctorChange} options={doctorOptions} value={doctorId} valueForAll={user.Role.id == 4 ? null : "all"} labelForAll="All Doctors" />
            </Col>
            {/* #298: clinic filter */}
            <Col xs={12} lg={3}>
              <MultiSelect placeholder={"Select Clinic"} onChange={handleClinicChange} options={clinicOptions} value={clinicId} valueForAll={user.Role.id == 3 ? null : "all"} labelForAll="All Clinics"/>
            </Col>
            <Col xs={12} lg={1}></Col>
            {/* #270: added startDate to change lower boundary of monthly slider for Monthly Performance  */}
            <Col xs={12} lg={5} className={`${!selectedComponent || !componentMapping[selectedComponent].monthSlider || componentMapping[selectedComponent].monthSliderType === 1 ? 'd-none' : ''}`}>
              <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, type: 2, max: new Date().getMonth()+1, startDate: startOfMonth(sub(Date.now(), {months: new Date().getMonth()+1}))})} handleMonthChange={handleDateRangeChange} />
            </Col>
            <Col xs={12} lg={5} className={`${!selectedComponent || !componentMapping[selectedComponent].monthSlider || componentMapping[selectedComponent].monthSliderType === 2 ? 'd-none' : ''}`}>
              <MonthSlider {...Object.assign({}, MONTH_SLIDER, {display: true, type: 1})} handleMonthChange={handleMonthChange} />
            </Col>
            <Col xs={12} lg={5} className={`${!selectedComponent || !componentMapping[selectedComponent].yearlySlider ? 'd-none' : ''}`}>
              <YearSlider handleChange={handleYearChange} />
            </Col>
          </Row>
          {selectedComponent && getSelectedComponent(selectedComponent)}
          <Row className="doctor-performance-report justify-content-center">
            {!selectedComponent && Object.keys(componentMapping).map((drPerforamnceReportComponent, index) => {
              return (
                <div key={index} style={{ width: `${componentMapping[drPerforamnceReportComponent].widthPercentage}%` }}>
                  <div className="mb-4" onClick={() => setSelectedComponent(drPerforamnceReportComponent)}>
                    <div className={`pill-container px-4 pt-3${drPerforamnceReportComponent === 'Tabulated Metrics Report (YTD by Month)' ? ' limit-height' : ''}`}>
                      <div className="pill-text mt-3 mb-3">{drPerforamnceReportComponent}</div>
                      {getSelectedComponent(drPerforamnceReportComponent, true)}
                    </div>
                  </div>
                </div>
              )
            })}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const {drPerformanceReducer} = state;
  return {
    ytdData: drPerformanceReducer.ytd,
    yearlySummaryData: drPerformanceReducer.yearly,
    summaryData: drPerformanceReducer.treatmentMix,
    rollingData: drPerformanceReducer.rolling,
    mtdPerformanceData: drPerformanceReducer.mtdPerformance,
    mtdData: drPerformanceReducer.mtdCurrVsPrevYear,
    monthlyPerformanceData: drPerformanceReducer.mtdCurrYear,
    monthlySummaryData: drPerformanceReducer.monthly,
  }
};

Renderer = connect(mapStateToProps, (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(authAction, dispatch),
    drPerformanceAction: bindActionCreators(drPerformanceAction, dispatch),
  },
}))(Renderer)

export default connect(null, (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(authAction, dispatch),
  },
}))(PerformanceReport)
