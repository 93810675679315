import React from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Breadcrumb, } from 'react-bootstrap';
import { Routing } from "routes";
import { useHistory } from "react-router-dom";
import './style.css';
import { KpiDashboard } from "./KpiDashboard";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(()=>({
    p:{
        fontFamily:'Poppins',
        fontWeight:'500',
        color:'#2f4858'
    }
}))

const KpiPlanner = () => {
    const classes = useStyles();
    const history = useHistory();
    const redirectUrl = (url) => {
        history.push(url);
    };
    
    return (
        <>
        <div className="search" style={{width:"20%"}}>
        </div>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
            <div className="d-block mb-4 mb-md-0">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                    <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                    <Breadcrumb.Item active className={classes.p}> KPI Planner</Breadcrumb.Item>
                </Breadcrumb>
                <h4 className={classes.p} style={{ marginBottom: '1rem' }}>KPI Planner Dashboard</h4>
            </div>
        </div>
        <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
                <Row className="justify-content-center ">
                    <Col xs={12} lg={11}  md = {11}>                        
                        <KpiDashboard className={classes.p}/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        </>
    )

}

export default KpiPlanner;
