import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Container, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as authAction from 'actions/authActions';
import Logo from 'assets/img/brand/Medtrik-Logo.png';
import Loader from '../Loader';
import axios from 'axios';
import { LOGIN_URL } from 'RouteConfig';

import './Login.css';
import ENVIRONMENT_VARIABLES from 'config';

const Login = (props) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [otpValidation, setOtpValidation] = useState(false);
  const [forgotPassowrd, setForgotPassowrd] = useState(false);
  const [forgotPassowrdSent, setForgotPassowrdSent] = useState(false);
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const otpSetting = ENVIRONMENT_VARIABLES.ENABLE_2FA;
  const handleCloseError = () => {
    setError(false);
  };
  const handleCloseErrorOtp = () => {
    setErrorOtp(false);
  };
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      history.push('/dashboard');
    }
  }, []);

  function hasValue(value) {
    return value.length >= 0;
  }

  function isFormValid(userName, password, otp, otpValidation) {
    return hasValue(userName) && hasValue(password) && (otpValidation !== true ? hasValue(otp) : true);
  }
  const adminAuthReducer = useSelector((state) => ({
    isLoading: state.adminAuthReducer.loading,
    error_msg: state.adminAuthReducer.error_msg,
  }));

  function validateForm() {
    if (otpValidation !== true) {
      if (otpSetting == 'false') {
        return userName.length > 0 && password.length > 0;
      } else {
        return userName.length > 0 && password.length > 0 && otp.length > 0;
      }
    } else {
      return userName.length > 0 && password.length > 0 && otp.length >= 0;
    }
  }

  // function validateFormLogin() {
  //   return userName.length > 0 && password.length > 0;
  // }

  useEffect(() => {
    if (adminAuthReducer.error_msg !== null) {
      toast.error(adminAuthReducer.error_msg, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }, [adminAuthReducer.error_msg]);

  function handleSubmitNonOTP(event) {
    event.preventDefault();
    const credentials = { userName, password };

    if (validateForm()) {
      props.actions.auth.loginUser(credentials);
      if (localStorage.getItem('accessToken')) {
        history.go('/dashboard');
      }
    } else {
      let toastId = this.notify('Please Enter Username and Password');
      this.setState({ toastId: toastId });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const credentials = { userName, password };

    if (validateForm()) {
      // props.actions.auth.loginUser(credentials);
      const checkLogin = async () => {
        const loginAPi = await fetch(`${ENVIRONMENT_VARIABLES.Base_API_URL}/Oauths/login`, {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            userName: credentials.userName,
            password: credentials.password,
          }),
        });
        // LOGIN_2FA => small letter
        const result = await loginAPi.json();
        if (loginAPi.status === 200) {
          localStorage.setItem('accessToken', result.accessToken);
          axios({
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/Oauths/login_2fa`,
            data: {
              secretKey: otp,
            },
          })
            .then((response) => {
              if (response.data.data) {
                history.push('/dashboard');
              }
            })
            .catch((error) => {
              setErrorOtp(true);
              localStorage.removeItem('accessToken', result.accessToken);
            });
        } else {
          setError(true);
        }
      };
      checkLogin();
    }
  }

  function handleOtpSubmit(event) {
    event.preventDefault();
    const credentials = { userName, password };
    if (validateForm()) {
      const checkLogin = async () => {
        const loginAPi = await fetch(`${ENVIRONMENT_VARIABLES.Base_API_URL}/Oauths/login`, {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({
            userName: credentials.userName,
            password: credentials.password,
          }),
        });
        const result = await loginAPi.json();
        if (loginAPi.status === 200) {
          localStorage.setItem('accessToken', result.accessToken);
          history.push('/check2fa');
        } else {
          setError(true);
        }
      };
      setOtpValidation(true);
      checkLogin();
    }
  }

  function handleForgotPassword() {
    props.actions.auth.forgotPassword(userName);
    setForgotPassowrdSent(true);
  }

  function handleForgotPasswordCancel() {
    setForgotPassowrd;
    // Sent(false);
    setForgotPassowrd(false);
    setOtpValidation(false);
  }

  return (
    <>
      {otpSetting == 'true' ? (
        <main>
          <ToastContainer />
          {error && (
            <Alert className="mt-2" variant="danger" onClose={handleCloseError} dismissible>
              Failed verification. Please check your email or password.
            </Alert>
          )}
          {errorOtp && (
            <Alert className="mt-2" variant="danger" onClose={handleCloseErrorOtp} dismissible>
              Failed verification. Please check your OTP code
            </Alert>
          )}
          <section className="d-flex vh-100 bg-white">
            <Container className="mt-6">
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${Logo})` }}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div
                    className={`bg-white border-0 rounded border-light p-4 w-100${
                      forgotPassowrd ? ' fmxw-800' : ' fmxw-500'
                    }`}
                  >
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <img src={Logo} alt="Medtrik Logo" />
                    </div>
                    {}
                    {forgotPassowrd ? (
                      <div>
                        <div className="auth-banner mb-5">
                          {forgotPassowrdSent
                            ? 'A reset password link has been sent to the provided e-mail. Please follow directions provided in the e-mail to reset your password.'
                            : 'Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.'}
                        </div>
                        {!forgotPassowrdSent && (
                          <Form.Group id="userName" className="d-flex align-items-center mb-5">
                            <Col xs={4}>
                              <Form.Label className="me-2">Email Address:</Form.Label>
                            </Col>
                            <Col xs={8}>
                              <Form.Control
                                autoFocus
                                required
                                type="email"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </Col>
                          </Form.Group>
                        )}
                        <div className="text-end">
                          {forgotPassowrdSent ? (
                            <Button
                              variant="gray-800"
                              className="login-button px-5"
                              onClick={handleForgotPasswordCancel}
                            >
                              Back to Login
                            </Button>
                          ) : (
                            <>
                              <Button variant="link" onClick={handleForgotPasswordCancel}>
                                Cancel
                              </Button>
                              <Button
                                variant="gray-800"
                                className="login-button px-5"
                                onClick={handleForgotPassword}
                                disabled={!(userName.length > 0)}
                              >
                                Reset Password
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    ) : otpValidation ? (
                      <Form className="mt-3" onSubmit={handleOtpSubmit}>
                        <Form.Group id="userName" className="mb-3">
                          <Container>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <Form.Label className="me-2">User ID/ Email: </Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  autoFocus
                                  required
                                  type="text"
                                  value={userName}
                                  onChange={(e) => setUserName(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Form.Group>
                        <Form.Group id="password" className="mb-3">
                          <Container>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <Form.Label className="me-2">Password:</Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  required
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Form.Group>

                        <Button
                          variant="gray-800"
                          className="login-button px-5 mt-3"
                          type="submit"
                          disabled={!validateForm()}
                        >
                          Setup 2FA
                        </Button>
                      </Form>
                    ) : (
                      <Form className="mt-3" onSubmit={handleSubmit}>
                        <Form.Group id="userName" className="mb-3">
                          <Container>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <Form.Label className="me-2">User ID/ Email: </Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  autoFocus
                                  required
                                  type="text"
                                  value={userName}
                                  onChange={(e) => setUserName(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Form.Group>
                        <Form.Group id="password" className="mb-3">
                          <Container>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <Form.Label className="me-2">Password:</Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  required
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Form.Group>
                        <Form.Group id="otp" className="mb-3">
                          <Container>
                            <Row className="align-items-center">
                              <Col xs={4}>
                                <Form.Label className="me-2">OTP:</Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  required
                                  type="password"
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </Form.Group>
                        <Container>
                          <Row className="d-flex align-items-center justify-content-end">
                            <Col xs={4} className="text-end">
                              <div className="setup-otp" onClick={() => setOtpValidation(true)}>
                                Setup 2FA
                              </div>
                            </Col>
                            <Col xs={4} className="text-end">
                              <div
                                className="forgot-password text-end mb-3 text-nowrap"
                                onClick={() => setForgotPassowrd(true)}
                              >
                                Forgot Password?
                              </div>
                            </Col>
                          </Row>
                        </Container>

                        <div className="mb-5  d-block">
                          <Button
                            variant="gray-800"
                            className="login-button px-5 d-block"
                            type="submit"
                            disabled={!validateForm()}
                          >
                            Login
                          </Button>
                        </div>

                        <Container className="mt-5  justify-content-center text-center">
                          <div>
                            <p style={{ color: '#757575', textAlign: 'justify' }}>
                              Note: Please be advised that this system requires Two-Factor Authentication (2FA). If you
                              have not already set up the 2FA, please do so by clicking on "Setup 2FA". Thank you for
                              your cooperation.
                            </p>
                          </div>
                        </Container>
                      </Form>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {adminAuthReducer.isLoading ? <Loader /> : ''}
        </main>
      ) : (
        <main>
          <ToastContainer />
          <section className="d-flex vh-100 bg-white">
            <Container className="mt-6">
              <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${Logo})` }}>
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div
                    className={`bg-white border-0 rounded border-light p-4 w-100${
                      forgotPassowrd ? ' fmxw-800' : ' fmxw-500'
                    }`}
                  >
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <img src={Logo} alt="Medtrik Logo" />
                    </div>
                    {forgotPassowrd ? (
                      <div>
                        <div className="auth-banner mb-5">
                          {forgotPassowrdSent
                            ? 'A reset password link has been sent to the provided e-mail. Please follow directions provided in the e-mail to reset your password.'
                            : 'Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.'}
                        </div>
                        {!forgotPassowrdSent && (
                          <Form.Group id="userName" className="d-flex align-items-center mb-5">
                            <Col xs={4}>
                              <Form.Label className="me-2">Email Address:</Form.Label>
                            </Col>
                            <Col xs={8}>
                              <Form.Control
                                autoFocus
                                required
                                type="email"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </Col>
                          </Form.Group>
                        )}
                        <div className="text-end">
                          {forgotPassowrdSent ? (
                            <Button
                              variant="gray-800"
                              className="login-button px-5"
                              onClick={handleForgotPasswordCancel}
                            >
                              Back to Login
                            </Button>
                          ) : (
                            <>
                              <Button variant="link" onClick={handleForgotPasswordCancel}>
                                Cancel
                              </Button>
                              <Button
                                variant="gray-800"
                                className="login-button px-5"
                                onClick={handleForgotPassword}
                                disabled={!(userName.length > 0)}
                              >
                                Reset Password
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Form className="mt-3" onSubmit={handleSubmitNonOTP}>
                        <Form.Group id="userName" className="d-flex align-items-center mb-3">
                          <Col xs={4}>
                            <Form.Label className="me-2">User ID/ Email: </Form.Label>
                          </Col>
                          <Col xs={8}>
                            <Form.Control
                              autoFocus
                              required
                              type="text"
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group id="password" className="d-flex align-items-center mb-3">
                          <Col xs={4}>
                            <Form.Label className="me-2">Password:</Form.Label>
                          </Col>
                          <Col xs={8}>
                            <Form.Control
                              required
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Col>
                        </Form.Group>
                        <div className="forgot-password text-end mb-5" onClick={() => setForgotPassowrd(true)}>
                          Forgot Password?
                        </div>
                        <Button
                          variant="gray-800"
                          className="login-button px-5"
                          type="submit"
                          disabled={!validateForm()}
                        >
                          Login
                        </Button>
                      </Form>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {adminAuthReducer.isLoading ? <Loader /> : ''}
        </main>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { adminAuthReducer } = state;
  return {
    loading: adminAuthReducer.loading,
    error_msg: adminAuthReducer.error_msg,
    isAuthenticated: adminAuthReducer.isAuthenticated,
    token: adminAuthReducer.token,
    newUserSuccess: adminAuthReducer.newUserSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(authAction, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
