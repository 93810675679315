import axios from "axios"

const { ADMIN_INPROGRESS, ADMIN_ERROR, ADMIN_DONEPROGRESS } = require("constants/actionsTypes")


const processRequest = (api, type) => {
  console.debug(`Dispatching ${type}`)
  return (dispatch) => {
    dispatch({ type: ADMIN_INPROGRESS });
    axios(api)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type,
            data: response.data,
          })
        };
        dispatch({type: ADMIN_DONEPROGRESS });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          (error.response.status === 400 ||
            error.response.status === 403 ||
            error.response.status === 401)
        ) {
          dispatch({
            type: ADMIN_ERROR,
            data: { action_type: type,
              error_msg: error.response.data.user_msg },
          });
        } else {
          dispatch({
            type: ADMIN_ERROR,
            data: { action_type: type,
              error_msg: error.message.toString() },
          });
        }
        dispatch({type: ADMIN_DONEPROGRESS });
      })
  }
}

export default processRequest