import React, { useState, useEffect } from "react";
import { Col, Row } from 'react-bootstrap';
import _ from "lodash";
import Chart from "react-apexcharts";

import '../style.css';
import { Button } from "react-bootstrap";

const FirstTreatmentWithDentalClinic = (props) => {
    const [isShowAll, setisShowAll] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [ chartOptions, setChartOptions ] = useState({
        chart: {
            type: 'bar'
        },
        colors: ['#7D7674'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                barHeight: '80%',
                dataLabels: {
                    position: 'top',
                }
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: 36,
            style: {
                fontSize: '12px'
            }
        },
        title: {
            text: !props.isEmbedded ? 'First Treatment with Dental Clinic' : '',
            align: 'center'
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '14px'
                },
                align: 'right',
                minWidth: 0,
                maxWidth: 450,
                offsetY: 4,
            },
        },
        xaxis: {
            floating: true,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            },
            categories: []
        },
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                const treatmentNames = w.config.xaxis.categories;
                const total = _.sum(series[seriesIndex])
                return (
                    `<div>
                        <p> First Treatment: <b>${treatmentNames[dataPointIndex]}</b> </p>
                        <p> No. of Treatments: <b>${series[seriesIndex][dataPointIndex]}</b> </p>
                        <p> % No. of Treatments: <b>${(series[seriesIndex][dataPointIndex] / total * 100).toFixed(2)}%</b> </p>
                    </div>`
                );
            },
        }
    });

    const [ chartSeries, setChartSeries ] = useState([{   
        data: []
    }]);

    const firstTreatmentData = props.firstTreatmentData;

    // useEffect(() => {
    //     const simulateNetworkRequest= () => {

    //         return new Promise((resolve) => setTimeout(resolve, 2000));
    //     }
    
    //     if (isLoading) {
    //       simulateNetworkRequest().then(() => {
    //         setLoading(false);
    //       });
    //     }
    //   }, [isLoading]);
    
    const handleClick = () => {
        setLoading(true)
        if (!isShowAll) {
            setisShowAll(true);
        } else {
            setisShowAll(false)
        }
    };
    
    
    useEffect(() => {
        if(!firstTreatmentData) return;

        if (!isShowAll) {
            setChartSeries([{...chartSeries[0], data: Object.values(firstTreatmentData).slice(0,5)}]);
            setChartOptions({...chartOptions, xaxis: {
                categories: Object.keys(firstTreatmentData).slice(0,5)
            }});
            setLoading(false)
        } else {
            setChartSeries([{...chartSeries[0], data: Object.values(firstTreatmentData)}]);
            setChartOptions({...chartOptions, xaxis: {
                categories: Object.keys(firstTreatmentData)
            }});
            setLoading(false)
        }
    }, [firstTreatmentData,isLoading]);

    
    useEffect(() => {
        if (!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                "/patient/dashboard/first-treatment-with-dental-clinic"
            );
            window.scrollTo(0,0);
        }
    }, []);
 
    return (

        <Row className={!props.isEmbedded ? "pb-5" : "pb-4"}>
            <Button
                        variant="primary"
                        disabled={isLoading}
                        onClick={!isLoading ? handleClick : null}
            >
                        {isLoading ? 'Loading…' : !isShowAll ? 'Show All': 'Show Top 5'}
            </Button>
            <Col xs={12} lg={12}  className={!props.isEmbedded ? "px-4 mt-4" : ""}>
                <Chart options={chartOptions} series={chartSeries} height={chartSeries[0].data.length === 1 ? 100 : chartSeries[0].data.length * _.max([(80 - chartSeries[0].data.length * 10), 20])} type="bar" />
            </Col>
        </Row>
    )
}

export default FirstTreatmentWithDentalClinic
